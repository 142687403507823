export default function(error) {
	let errors = {
		invalid_number: 'Numéro de la carte bancaire non valide.',
		invalid_expiry_month: 'Mois d’expiration de la carte bancaire non valide.',
		invalid_expiry_year: "Année d'expiration de la carte bancaire non valide.",
		invalid_cvc: 'Code de sécurité de la carte bancaire non valide.',
		incorrect_number: 'Numéro de la carte bancaire non valide.',
		expired_card: 'Carte bancaire expirée.',
		incorrect_cvc: 'Code de sécurité de la carte bancaire incorrecte.',
		card_declined: 'Carte bancaire refusée.',
		processing_error: "Une erreur s'est produite, merci de réessayer."
	};

	return errors[error];
}
