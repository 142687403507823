import React, { Component } from 'react';
import SvgMarker from '../assets/SvgMarker'


class Address extends Component {

    render() {
        return (
            <button className="flex" style={{ width: '95%', cursor: 'pointer', outline: 0, borderWidth: 0, borderBottom: '1px solid #F0F0F3', alignItems: 'center', flexDirection: 'row', backgroundColor: '#F9F9F9' }} onClick={this.props.onClick}>
                <div style={{ width: '10%' }}>
                    <SvgMarker />
                </div>
                <div style={{ width: '90%' }}>
                    <p class="text text-align-left" style={{ marginLeft: 5, color: "#222222" }}>{this.props.address}</p>
                </div>
            </button>
        )
    }
}

export default Address;