import React, { Component } from 'react';

class Button extends Component {

    render() {
        return (
            <button className="button" style={{ width: this.props.width, height: this.props.height, borderRadius: this.props.no_border_radius ? 0 : '5px', backgroundColor: this.props.disabled ? "#C4C4C4" : this.props.backgroundColor ? this.props.backgroundColor : '#00B456', color: this.props.color ? this.props.color : 'white', alignSelf: 'center', cursor: 'pointer', outline: 0, borderWidth: 0, marginBottom: this.props.marginBottom ? this.props.marginBottom : 0, fontFamily: "Montserrat-Bold", marginTop: this.props.marginTop ? this.props.marginTop : 10 }}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {this.props.text}

            </button>
        )
    }
}

export default Button; 