import * as React from "react"

function SvgCar(props) {
    return (
        <svg width={'50px'} height={'50px'} viewBox="0 0 50.197 41.691" {...props}>
            <g data-name="Groupe 4140">
                <g data-name="Groupe 4137" transform="translate(0 6.246)">
                    <g>
                        <g data-name="Groupe 4123">
                            <path
                                data-name="Trac\xE9 1352"
                                d="M48.716 10.644h-3.494a1.465 1.465 0 00-.629.143q.036.58.037 1.169v2.894a7.328 7.328 0 011.231 1.361h2.856a1.485 1.485 0 001.481-1.481v-2.606a1.485 1.485 0 00-1.482-1.48z"
                                fill="#dfebfa"
                            />
                        </g>
                        <g data-name="Groupe 4124" fill="#b1dbfc">
                            <path data-name="Trac\xE9 1353" d="M44.629 14.85z" />
                            <path
                                data-name="Trac\xE9 1354"
                                d="M47.542 14.73v-2.606a1.486 1.486 0 011.328-1.473 1.466 1.466 0 00-.153-.008h-3.494a1.465 1.465 0 00-.629.143q.036.58.037 1.169v2.894a7.328 7.328 0 011.231 1.361h2.856a1.51 1.51 0 00.153-.008 1.486 1.486 0 01-1.329-1.472z"
                            />
                        </g>
                        <g data-name="Groupe 4125">
                            <path
                                data-name="Trac\xE9 1355"
                                d="M4.77 14.85v-2.894c0-.441.017-.879.048-1.312H1.481A1.485 1.485 0 000 12.125v2.606a1.485 1.485 0 001.481 1.481h2.058A7.332 7.332 0 014.77 14.85z"
                                fill="#dfebfa"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1356"
                            d="M2.949 14.73v-2.606a1.485 1.485 0 011.481-1.481H1.481A1.485 1.485 0 000 12.124v2.606a1.485 1.485 0 001.481 1.481h2.058c.048-.069.1-.134.15-.2a1.483 1.483 0 01-.74-1.281z"
                            fill="#b1dbfc"
                        />
                        <g data-name="Groupe 4126">
                            <path
                                data-name="Trac\xE9 1357"
                                d="M9.276 13.212h30.847a7.165 7.165 0 014.708 1.769v-2.919a16.232 16.232 0 00-2.1-8.28C41.258 1.343 39.239 0 37.047 0h-24.7c-2.193 0-4.211 1.343-5.684 3.782a16.231 16.231 0 00-2.1 8.28v2.919a7.165 7.165 0 014.713-1.769z"
                                fill="#00ba66"
                            />
                        </g>
                        <g data-name="Groupe 4127">
                            <path
                                data-name="Trac\xE9 1358"
                                d="M8.618 12.062a16.231 16.231 0 012.1-8.28C12.191 1.343 14.21 0 16.402 0h-4.05c-2.193 0-4.211 1.343-5.684 3.782a16.231 16.231 0 00-2.1 8.28v2.919a7.18 7.18 0 014.05-1.739v-1.18z"
                                fill="#00a96c"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1359"
                            d="M40.085 6.711a8.935 8.935 0 00-.5-.974c-.451-.757-1.38-2.026-2.536-2.026h-24.7c-1.157 0-2.086 1.269-2.537 2.026a8.932 8.932 0 00-.5.974 10.347 10.347 0 00-.844 4.167v2.5a7.066 7.066 0 01.806-.048h30.847a7.061 7.061 0 01.805.048v-2.5a10.348 10.348 0 00-.841-4.167z"
                            fill="#60b8fe"
                        />
                        <g data-name="Groupe 4128">
                            <path
                                data-name="Trac\xE9 1360"
                                d="M12.522 10.878a10.35 10.35 0 01.844-4.167 8.937 8.937 0 01.5-.974c.451-.757 1.38-2.026 2.537-2.026h-4.05c-1.157 0-2.086 1.269-2.537 2.026a8.934 8.934 0 00-.5.974 10.348 10.348 0 00-.844 4.167v2.5a7.069 7.069 0 01.806-.048h3.244z"
                                fill="#23a8fe"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1361"
                            d="M40.123 13.098H9.276a7.261 7.261 0 00-7.239 7.239v7.4a1.209 1.209 0 01.336-.048h16.859a1.222 1.222 0 01.979-.494h8.978a1.222 1.222 0 01.979.494h16.859a1.209 1.209 0 01.336.048v-7.4a7.261 7.261 0 00-7.24-7.239z"
                            fill="#43cb8e"
                        />
                        <g data-name="Groupe 4129">
                            <path
                                data-name="Trac\xE9 1362"
                                d="M6.242 20.336a7.261 7.261 0 017.239-7.239h-4.2a7.261 7.261 0 00-7.239 7.239v7.4a1.209 1.209 0 01.336-.048h3.864z"
                                fill="#00ba66"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1363"
                            d="M29.978 20.166a3.86 3.86 0 003.29-1.842l3.2-5.227H12.932l3.2 5.227a3.859 3.859 0 003.29 1.842z"
                            fill="#00ba66"
                        />
                        <path
                            data-name="Trac\xE9 1364"
                            d="M20.223 18.324l-3.2-5.227h-4.091l3.2 5.227a3.859 3.859 0 003.29 1.842h4.091a3.858 3.858 0 01-3.29-1.842z"
                            fill="#00a96c"
                        />
                        <circle
                            data-name="Ellipse 348"
                            cx={2.953}
                            cy={2.953}
                            r={2.953}
                            transform="translate(37.887 17.407)"
                            fill="#ffd301"
                        />
                        <path
                            data-name="Trac\xE9 1365"
                            d="M40.84 20.36a2.949 2.949 0 011.476-2.554 2.953 2.953 0 100 5.109 2.949 2.949 0 01-1.476-2.555z"
                            fill="#ffc20c"
                        />
                        <g data-name="Groupe 4130">
                            <path
                                data-name="Trac\xE9 1366"
                                d="M36.783 30.707v3.238a1.5 1.5 0 001.5 1.5h6.635a1.5 1.5 0 001.5-1.5v-3.238z"
                                fill="#665e68"
                            />
                        </g>
                        <g data-name="Groupe 4132">
                            <path
                                data-name="Trac\xE9 1366"
                                d="M13.393 30.707v3.238a1.51 1.51 0 01-1.511 1.5H5.194a1.51 1.51 0 01-1.511-1.5v-3.238z"
                                fill="#665e68"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1367"
                            d="M41.914 33.945v-3.238h-5.131v3.238a1.5 1.5 0 001.5 1.5h5.131a1.5 1.5 0 01-1.5-1.5z"
                            fill="#544e55"
                        />
                        <path
                            data-name="Trac\xE9 1368"
                            d="M47.027 27.187h-16.96a1.214 1.214 0 01.244.729v2.365a1.214 1.214 0 01-.244.729h16.96a1.227 1.227 0 001.224-1.224V28.41a1.227 1.227 0 00-1.224-1.223z"
                            fill="#dfebfa"
                        />
                        <path
                            data-name="Trac\xE9 1411"
                            d="M3.069 27.187h17.093a1.207 1.207 0 00-.246.729v2.365a1.207 1.207 0 00.246.729H3.069a1.232 1.232 0 01-1.233-1.224V28.41a1.232 1.232 0 011.233-1.223z"
                            fill="#dfebfa"
                        />
                        <path
                            data-name="Trac\xE9 1369"
                            d="M29.492 31.504h-9.584a1.227 1.227 0 01-1.224-1.224v-2.365a1.227 1.227 0 011.224-1.223h9.584a1.227 1.227 0 011.224 1.223v2.365a1.227 1.227 0 01-1.224 1.224z"
                            fill="#b1dbfc"
                        />
                        <path
                            data-name="Trac\xE9 1370"
                            d="M28.067 30.281v-2.365a1.227 1.227 0 011.223-1.224h-9.386a1.227 1.227 0 00-1.224 1.224v2.365a1.227 1.227 0 001.224 1.224h9.386a1.227 1.227 0 01-1.223-1.224z"
                            fill="#8bcaff"
                        />
                        <g data-name="Groupe 4131">
                            <path
                                data-name="Trac\xE9 1371"
                                d="M30.686 24.41h-7.312a1.106 1.106 0 110-2.211h7.312a1.106 1.106 0 110 2.211z"
                                fill="#00ba66"
                            />
                        </g>
                    </g>
                    <circle
                        data-name="Ellipse 350"
                        cx={2.953}
                        cy={2.953}
                        r={2.953}
                        transform="translate(8.641 18.477)"
                        fill="#ffd301"
                    />
                    <path
                        data-name="Trac\xE9 1413"
                        d="M11.594 21.43a2.949 2.949 0 011.476-2.554 2.953 2.953 0 100 5.109 2.949 2.949 0 01-1.476-2.555z"
                        fill="#ffc20c"
                    />
                </g>
                <g data-name="Groupe 4138">
                    <g data-name="Groupe 4119" transform="translate(4.391)">
                        <circle
                            data-name="Ellipse 347"
                            cx={7.328}
                            cy={7.328}
                            r={7.328}
                            fill="#ffc964"
                        />
                        <path
                            data-name="Trac\xE9 1334"
                            d="M2.039 7.326A7.328 7.328 0 018.348.07a7.328 7.328 0 100 14.512 7.328 7.328 0 01-6.309-7.256z"
                            fill="#f0b952"
                        />
                        <path
                            data-name="Trac\xE9 1335"
                            d="M7.329 9.649a.643.643 0 00-.643.643v4.335a7.192 7.192 0 001.286 0v-4.335a.643.643 0 00-.643-.643z"
                            fill="#dfebfa"
                        />
                        <g data-name="Groupe 4114">
                            <path
                                data-name="Trac\xE9 1336"
                                d="M9.016 6.965a.643.643 0 01-.647-.638V3.876a.643.643 0 111.285 0v2.451a.643.643 0 01-.638.638z"
                                fill="#dfebfa"
                            />
                        </g>
                        <g data-name="Groupe 4115">
                            <path
                                data-name="Trac\xE9 1337"
                                d="M5.641 6.965a.643.643 0 01-.643-.643V3.871a.643.643 0 011.285 0v2.456a.643.643 0 01-.642.638z"
                                fill="#dfebfa"
                            />
                        </g>
                        <path
                            data-name="Trac\xE9 1338"
                            d="M9.551 6.131H5.1a.765.765 0 00-.762.762v1.823a2.381 2.381 0 002.374 2.374H7.94a2.381 2.381 0 002.374-2.374V6.893a.765.765 0 00-.763-.762z"
                            fill="#665e68"
                        />
                        <path
                            data-name="Trac\xE9 1339"
                            d="M6.381 8.716V6.893a.765.765 0 01.762-.762H5.1a.765.765 0 00-.762.762v1.823a2.381 2.381 0 002.374 2.374H7.94a2.358 2.358 0 00.408-.036 2.383 2.383 0 01-1.967-2.338z"
                            fill="#554e56"
                        />
                        <g data-name="Groupe 4116">
                            <path
                                data-name="Trac\xE9 1340"
                                d="M7.455 8.967h-.253a.643.643 0 010-1.285h.253a.643.643 0 110 1.285z"
                                fill="#7b6c79"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SvgCar
