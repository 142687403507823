import React, { Component } from 'react';
import Input from './Input'
import Button from './Button'
import SvgLogo from '../assets/SvgLogo'
import swal from '@sweetalert/with-react'
import Swal from 'sweetalert2'
import WebServices from './../WebServices'

import 'sweetalert2/dist/sweetalert2.min.css'
class Login extends Component {
 
    render() {
        return (
            <div className="flex center" style={{width:"22vmax"}}>
                <img src={require('../assets/logo.png')} style={{ width: '160px', height: '51px' }} />
                <p className="title">Se connecter</p>
                <Input
                    placeholder="Email"
                    value={this.props.email}
                    onChange={this.props.onChangeEmail}
                    refinput="email"
                />
                <Input
                    placeholder="Mot de passe"
                    value={this.props.password}
                    onChange={this.props.onChangePassword}
                    refinput="password"
                    type="password"
                />
                < div class="flex width" style={{ alignItems: "flex-end" }}>
                    <p className="text  font-size black" style={{ textDecoration: 'underline', alignSelf: 'flex-end', cursor: 'pointer' }}
                        onClick={() => {
                            // swal("Une erreur s'est produite !", "", "error")
                            Swal.fire({
                                title: 'Cela arrive même aux meilleurs',
                                html: 'Entrez votre adresse mail pour rénitialiser votre mot de passe et laissez-vous guider',
                                input: 'text',
                                inputAttributes: {
                                  autocapitalize: 'off'
                                },
                                showCancelButton: true,
                                confirmButtonText: 'Envoyer',
                                showLoaderOnConfirm: true,
                                cancelButtonText: "Annuler",
                                preConfirm: (email) => {

                                    WebServices.requestNewpassword(email).then((res) => {
                                        
                                    })
                                },
                                allowOutsideClick: () => !Swal.isLoading()
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    Swal.fire('Success!', 'Un e-mail vous a été envoyé.', 'success')
                                }
                              })
                        }}
                    >
                        Mot de passe oublié ?
                </p>
                </ div>
                <Button
                    text="Connecter"
                    width={this.props.login_width}
                    onClick={this.props.login}

                />
                <div className="width" style={{ height: '.5vmax', marginTop: 20 }}>
                    <div className="line" />
                </div>
                <p className="text width font-size black"
                    onClick={this.props.register}
                >
                    Pas encore de compte ? <br /><span className="green" style={{ textDecoration: 'underline' }}> Inscrivez-vous !</span>
                </p>
            </div>
        )
    }
}

export default Login;