import React, { Component } from 'react';
import '../App.css';

class Input extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div 
                className="width flex" 
                style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#F9F9F9", width: this.props.width && this.props.width, border: '1px solid #EFEFEF', borderRadius: 10, outline: 0, alignItems: 'center', justifyContent: 'center', ...this.props.style }} 
                placeholder={this.props.placeholder}
            >

                <input 
                    type={this.props.type ? this.props.type : "text"} 
                    className="inputStyle" ref={this.props.refinput} 
                    onFocus={this.props.onFocus}
                    style={{ width: this.props.width_input, height: this.props.height_input, color: '#737B91', outline: 0 }} placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    name={this.props.name}
                />
                
            </div>
        )
    }
}

export default Input;