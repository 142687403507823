import React, { Component } from 'react';
import Button from './Button'
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';
import Input from './Input'
import CheckButton from './CheckButton'
import SvgCollaborators from '../assets/SvgCollaborators'
import Select from 'react-select'
import moment from 'moment'
import { Row, Container, Col, Form, FormControl } from 'react-bootstrap';


class Summary extends Component {
    constructor(props) {

        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({ width: window.innerWidth, height: window.innerHeight })
        });
    }

    render() {

        return (

            <Container fluid className="min-width-bloc">
                <div style={{ width: ((window.innerWidth <= 1024)) && '80vmin' }}>

                    <h2 className="text-semibold">INFORMATIONS PERSONNELLES</h2>
                    <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />
                    <Row className="row " >
                        <Col >
                            <p className='text-medium' >Nom & Prénom:</p>
                        </Col>

                        <Col >
                            {
                                this.props.user.is_professional ?
                                <p className='text font-size'>{this.props.user.professional_name}</p> :
                                <p className='text font-size'>{this.props.user.firstname} {this.props.user.lastname}</p>
                            }
                            
                        </Col>
                        

                    </Row>
                    <Row className="row ">
                        <p className='text-medium' >Email:</p>
                        <Col >
                            <p className='text font-size'>{this.props.user.email} </p>
                        </Col>
                    </Row>

                    <Row className="row">
                        <p className='text-medium' >Téléphone:</p>
                        <p className='text font-size'>{this.props.user.phone_number} </p>
                    </Row>

                    <Row className="row">
                        <p className='text-medium' ></p>
                        <p className='text font-size' style={{ 'font-size': '12px', cursor: 'pointer', marginTop: 5 }} onClick={() => this.props.onDisconnect()}> [x] Se deconnecter </p>
                    </Row>

                    <>
                        <h2 className="text-semibold">VOTRE TRAJET</h2>
                        <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                        <Row className="row ">
                            <p className='text-medium' >Heure et date:</p>
                            <p className='text font-size'>{moment(this.props.date).format('LLL')}</p>
                        </Row>
                        {
                            this.props.is_one_way == 1 && 
                            <Row className="row ">
                                <p className='text-medium' >Heure et date de retour:</p>
                                <p className='text font-size'>{moment(this.props.return_date).format('LLL')}</p>
                            </Row>
                        }
                        

                        <Row className="row ">
                            <p className='text-medium' >Distance:</p>
                            <p className='text font-size'>{this.props.distance} Km</p>
                        </Row>
                        <Row className="row ">
                            <p className='text-medium'>Temps estimé:</p>
                            <p className='text font-size'>{this.props.duration} min</p>
                        </Row>
                        <Row className="row ">
                            <p className='text-medium'>Adresse de départ</p>
                            <p className='text font-size' style={{ maxWidth: (this.state.height > this.state.width) ? '20vmin' : (window.innerWidth <= 1024) ? '17vmin' : '16vmax', textAlign: "end" }}>{this.props.address_from}</p>
                        </Row>
                        <Row className="row ">
                            <p className='text-medium' >Adresse d’arrivée</p>
                            <p className='text font-size' style={{ maxWidth: ((window.innerWidth <= 1024)) ? '20vmin' : '16vmax', textAlign: "end" }}>{this.props.address_to}</p>
                        </Row>
                        <Row className="row ">
                            <p className='text-medium' >Aller/Retour</p>
                            <p className='text font-size'>{this.props.is_one_way  === 0 ? 'Aller simple' : 'Aller-retour'}</p>
                        </Row>

                        

                        <Row className="row ">
                            <p className='text-medium' >Bagages/Top Case</p>
                            <p className='text font-size'>{this.props.baggages  === "2" ? 'Oui' : 'Non'}</p>
                        </Row>
                        

                    </>


                    <h2 className="text-semibold">INFORMATIONS COMPLÉMENTAIRES</h2>
                    <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                    <p className='text black font-size' style={{ color: "#222222" }}>Informations complémentaires (facultatif)</p>
                    <Input
                        placeholder="Commentaire concernant votre commande"
                        value={this.props.comment}
                        onChange={this.props.onChangeComment}
                        width={((window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                        width_input={((window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                    />


                </div>
            </Container>
        )
    }
}

export default Summary;