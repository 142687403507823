import React, { Component } from 'react';
import BookingItem from './BookingItem'

const width = window.innerWidth
const height = window.innerHeight

class MyBookings extends Component {

    render() {
        return (
            <div className="my-bookings" style={{}}>
                <div style={{ width: (height > width) ? '90vmin' : '70vmax',  display: 'flex' }} >
                    {/* <img src={require('../assets/mybookings.png')} style={{ width: (height > width) ? '70vmin' : '55vmax' ,position:'absolute',top:-100}} /> */}
                    <p className="title" style={{ fontSize: '20px', }}>Mes réservations</p>
                </div>

                <div className="my-bookings-list">
                    {/* <div className="row center" style={{ width: (height > width) ? '85vmin' : '70vmax' }}>
                        <p className="label"  >Date et heure</p>
                        <p className="label" style={{ marginLeft: '1.5vmax' }} >Adresse de départ</p>
                        <p className="label" style={{ marginLeft: '5vmax' }}>Adresse d'arrivée </p>
                        <p className="label"  >Montant</p>
                        <p className="label" style={{ marginRight: '4vmax' }}>Statut</p>
                        <div />
                    </div> */}

                    <table style={{width: "90%"}}>

                        <tr stlye={{textAlign: 'left'}}>
                            <th stlye={{textAlign: 'left'}}> Date et heure </th>
                            <th> Passager </th>
                            <th> Adresse de départ </th>
                            <th> Adresse d'arrivé </th>
                            <th> Montant </th>
                            <th> Statut </th>
                        </tr>

                        <tr>
                            <td colspan="5">  
                            
                                <div className="line " style={{ width: (height > width) ? '85vmin' : "70vmax", marginBottom: 10, alignSelf: 'center' }} />
                            </td>

                        </tr>


                        {this.props.mybookings &&
                            this.props.mybookings.map((el, index) =>
                                <BookingItem
                                    backgroundColor={index % 2 === 0 ? '#FFFFFF' : '#F9F9F9'}
                                    address_from={el.address_from}
                                    date={el.trip_date}
                                    amount={el.amount_to_pay / 100}
                                    address_to={el.address_to}
                                    status={el.status}
                                    el={el}
                                />
                            )
                        }
                        
                    </table>
                       
                </div>
            </div>
        )
    }
}

export default MyBookings;