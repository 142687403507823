import * as React from "react"

function SvgCompany(props) {
    return (
        <svg width="15px" height="15px" viewBox="0 0 512 512" {...props}>
            <g>
                <path d="m497 369h-18v-33c0-8.284-6.716-15-15-15s-15 6.716-15 15v33h-20v143h68c8.284 0 15-6.716 15-15v-113c0-8.284-6.716-15-15-15z" fill="#00B456"/>
                <path d="m83 369h-20v-33c0-8.284-6.716-15-15-15s-15 6.716-15 15v33h-18c-8.284 0-15 6.716-15 15v113c0 8.284 6.716 15 15 15h68z" fill="#00B456"/>
                <path d="m113 512h72v-80c0-8.284 6.716-15 15-15h112c8.284 0 15 6.716 15 15v80h72c0-24.796 0-474.107 0-497 0-8.284-6.716-15-15-15h-256c-8.284 0-15 6.716-15 15zm103-161h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm96 192h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-16c-8.284 0-15-6.716-15-15s6.716-15 15-15h16c8.284 0 15 6.716 15 15s-6.716 15-15 15zm-120-94h128c8.284 0 15 6.716 15 15s-6.716 15-15 15h-128c-8.284 0-15-6.716-15-15s6.716-15 15-15z" fill="#00B456"/>
                <path d="m215 512h82v-65h-82z" fill="#00B456"/>
            </g>
        </svg>
    )
}

export default SvgCompany
