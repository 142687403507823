import * as React from "react"

function SvgCollaborators(props) {
    return (
        <svg width="15px" height="15px" viewBox="0 0 496 496" {...props}>
            <path d="m388 436.824h-280c-8.836 0-16-7.164-16-16v-47.37c0-22.566 13.314-43.093 33.92-52.293 59.93-26.76 56.645-25.855 61.315-25.855h19.017c7.364 0 13.777 5.026 15.537 12.177 6.707 27.256 45.713 27.269 52.423 0 1.76-7.15 8.173-12.177 15.537-12.177 20.466 0 21.517-.406 25.54 1.39l54.792 24.465c20.605 9.2 33.92 29.727 33.92 52.293v47.37c-.001 8.836-7.165 16-16.001 16zm-139.609-377.648c-53.89 0-97.732 43.842-97.732 97.732s43.842 97.732 97.732 97.732 97.732-43.842 97.732-97.732-43.842-97.732-97.732-97.732zm-154.173 104.536c-33.931 0-61.536 27.605-61.536 61.537 0 33.931 27.605 61.536 61.536 61.536 33.932 0 61.537-27.605 61.537-61.536-.001-33.932-27.606-61.537-61.537-61.537zm307.564 0c-33.932 0-61.537 27.605-61.537 61.537 0 33.931 27.605 61.536 61.537 61.536 33.931 0 61.536-27.605 61.536-61.536 0-33.932-27.605-61.537-61.536-61.537zm71.118 146.578c-33.292-14.868-32.495-15.02-37.05-15.02-23.833 0-39.424-.167-49.97-.219-10.482-.052-13.954 14.007-4.666 18.865 7.605 3.978 12.222 7.371 17.706 13.355 16.003 17.381 17.494 35.776 17.491 51.027-.001 5.523 4.474 9.993 9.997 9.993h53.592c8.84 0 16-7.17 16-16v-26.391c0-15.37-9.07-29.34-23.1-35.61zm-412.75-15.02c-4.534 0-3.642.1-37.05 15.02-14.03 6.27-23.1 20.24-23.1 35.61v26.39c0 8.83 7.16 16 16 16h53.588c5.526 0 10.004-4.476 9.997-10.001-.018-15.305 1.429-33.569 17.495-51.019 5.457-5.955 10.113-9.378 17.55-13.285 9.269-4.869 5.844-18.887-4.626-18.869-10.609.018-26.198.154-49.854.154z" fill="#00B456"/>
        </svg>
    )
}

export default SvgCollaborators
