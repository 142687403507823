import React, { Component } from 'react';
import SvgTick from '../assets/SvgTick'

class CheckButton extends Component {

    render() {
        return (
            <button className="flex" style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: 'white', cursor: 'pointer', outline: 0, borderWidth: 1, borderColor: "#EFEFEF", alignItems: 'center', justifyItems: 'center' }}
                onClick={this.props.onClick}
            >
                {this.props.selected && <SvgTick />}

            </button>
        )
    }
}

export default CheckButton;