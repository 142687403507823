import React, { Component } from 'react';
import Input from './Input'
import Button from './Button'
import SvgSuccess from '../assets/SvgSuccess'
import { Row, Container } from 'react-bootstrap';

class SuccessBooking extends Component {

    render() {
        return (
            <Container className="success-container" >
                <SvgSuccess />
                <p className="success-title">Félicitations, votre réservation<br />a bien été prise en compte !</p>
                <p className="success-text">Vous allez recevoir un email avec le detail de la réservation.</p>
                <Row className="success-row" style={{justifyContent: 'center'}}>
                    <Button
                        text="Retour"
                        backgroundColor="#8FCBB1"
                        width={'48%'}
                        onClick={() => {
                            window.parent.postMessage("change-page-to-home", "*")
                        }}
                    />
                    {/* <Button
                        text="Voir mes réservations"
                        backgroundColor="#B8B7BF"
                        width={'48%'}
                    /> */}
                </Row>
            </Container>
        )
    }
}

export default SuccessBooking;