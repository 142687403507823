import * as React from "react"

function SvgLogo(props) {
    return (
        <svg width={'6vmax'} height={'2vmax'} viewBox="0 0 865.494 295.342" {...props}>
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={-0.031}
                    y1={0.689}
                    x2={4.469}
                    y2={-0.915}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#00ff85" />
                    <stop offset={0.136} stopColor="#00e877" />
                    <stop offset={0.314} stopColor="#00d168" />
                    <stop offset={0.506} stopColor="#00c15e" />
                    <stop offset={0.72} stopColor="#00b758" />
                    <stop offset={1} stopColor="#00b456" />
                </linearGradient>
                <linearGradient
                    id="prefix__b"
                    x1={-2.429}
                    y1={1.527}
                    x2={2.127}
                    y2={-0.07}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#00ff85" />
                    <stop offset={1} stopColor="#00b456" />
                </linearGradient>
                <linearGradient
                    id="prefix__c"
                    x1={-3.369}
                    y1={1.327}
                    x2={1.131}
                    y2={0.365}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#00b456" />
                    <stop offset={0.43} stopColor="#00b657" />
                    <stop offset={0.61} stopColor="#00bd5c" />
                    <stop offset={0.743} stopColor="#00ca63" />
                    <stop offset={0.853} stopColor="#00db6f" />
                    <stop offset={0.948} stopColor="#00f27d" />
                    <stop offset={0.992} stopColor="#00ff85" />
                </linearGradient>
                <linearGradient
                    id="prefix__d"
                    y1={0.5}
                    x2={1}
                    y2={0.5}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#00ff85" />
                    <stop offset={0.165} stopColor="#00f17c" />
                    <stop offset={0.719} stopColor="#00c561" />
                    <stop offset={1} stopColor="#00b456" />
                </linearGradient>
            </defs>
            <g data-name="Groupe 4187">
                <path
                    data-name="Trac\xE9 1132"
                    d="M335.345 377.1H161.7a6.453 6.453 0 00-6.449 6.449v152.958a6.452 6.452 0 006.449 6.449h173.645a6.453 6.453 0 006.45-6.449v-43.015a6.453 6.453 0 00-6.45-6.45H217.437v-53.993h117.908a6.453 6.453 0 006.45-6.449v-43.015a6.46 6.46 0 00-6.45-6.485z"
                    transform="translate(-154.599 -266.435)"
                    fill="url(#prefix__a)"
                />
                <path
                    data-name="Trac\xE9 1133"
                    d="M594.971 314.3h-171.3a6.453 6.453 0 00-6.449 6.45v153.662a6.452 6.452 0 006.449 6.449h171.3a6.452 6.452 0 006.449-6.449V431.4a6.453 6.453 0 00-6.449-6.45H479.407v-54.718h115.564a6.452 6.452 0 006.449-6.45v-43.015a6.468 6.468 0 00-6.449-6.467z"
                    transform="translate(45.069 -314.3)"
                    fill="url(#prefix__b)"
                />
                <path
                    data-name="Trac\xE9 1134"
                    d="M720.265 534.866H602.357v-53.992h117.908a6.452 6.452 0 006.449-6.449v-43.016a6.453 6.453 0 00-6.449-6.45H602.357v-54.715h115.564a6.453 6.453 0 006.45-6.449V320.78a6.453 6.453 0 00-6.45-6.449h-171.3a6.453 6.453 0 00-6.449 6.449v263.6a6.453 6.453 0 006.449 6.449h173.644a6.453 6.453 0 006.449-6.449v-43.011a6.452 6.452 0 00-6.449-6.503z"
                    transform="translate(138.779 -314.277)"
                    fill="url(#prefix__c)"
                />
                <g data-name="Groupe 985" fill="#00b456">
                    <path
                        data-name="Trac\xE9 1135"
                        d="M644.216 219.638h-41.728v20.67h41.729a2.184 2.184 0 002.185-2.185v-16.282a2.2 2.2 0 00-2.186-2.203z"
                    />
                    <path
                        data-name="Trac\xE9 1136"
                        d="M644.216 255.815h-41.728v20.67h41.729a2.184 2.184 0 002.185-2.185v-16.282a2.188 2.188 0 00-2.186-2.203z"
                    />
                </g>
                <path
                    data-name="Trac\xE9 1137"
                    d="M738.055 515.817l-16.388-.07a2.879 2.879 0 00-2.943 2.82l-.035 5.163-25.833-.123a32.406 32.406 0 00-24.195 10.5v-.088l-213.594.934 118.154-165.379a6.383 6.383 0 001.057-3.56V320.85a6.453 6.453 0 00-6.45-6.45H161.33a6.453 6.453 0 00-6.45 6.45v43.015a6.467 6.467 0 006.467 6.467l331.5-.758-118.542 165.4a6.4 6.4 0 00-1.075 3.56v45.922a6.453 6.453 0 006.45 6.45l288.962-.053c.335.37.67.74 1.022 1.093a32.8 32.8 0 0022.785 9.216l25.834.123-.018 5.163a2.889 2.889 0 002.872 2.837l16.6.282a2.927 2.927 0 002.1-.811 2.75 2.75 0 00.881-2.009l.229-88.109a2.822 2.822 0 00-2.892-2.821zm-10.732 47.65l-14.75 24.459a2.862 2.862 0 11-4.9-2.96l11.172-18.521-15.683 3.877a2.518 2.518 0 01-2.943-1.058 2.626 2.626 0 01-.194-3.225l14.749-24.459a2.783 2.783 0 013.912-.969 2.8 2.8 0 01.969 3.93l-11.172 18.521 15.683-3.877a2.518 2.518 0 012.943 1.057 3.136 3.136 0 01.215 3.225z"
                    transform="translate(-154.881 -314.224)"
                    fill="url(#prefix__d)"
                />
            </g>
        </svg>
    )
}

export default SvgLogo
