import React, { Component } from 'react';
import Input from './Input'
import Button from './Button'
import SvgUser from '../assets/SvgUser'


class RegisterPro extends Component {

    render() {
        return (
            <div className="flex center" style={{ width: "22vmax" }}>

                <img src={require('../assets/logo.png')} style={{ width: '160px', height: '51px' }} />
                <p className="title">Inscrivez-vous</p>

                <div className="row  width center " style={{ justifyContent: 'flex-start' }}>
                    <SvgUser />
                    <p className="text  font-size green " style={{ marginLeft: 5, cursor: 'pointer' }}
                        onClick={this.props.signupPro}
                    >
                        Je suis un particulier
                </p>
                </div>


                

                <Input
                    placeholder="Nom d’entreprise"
                    value={this.props.company_name}
                    onChange={this.props.onChangeCompanyName}
                    refinput="company_name"

                />
                
                <Input
                    placeholder="Email"
                    value={this.props.email}
                    onChange={this.props.onChangeEmail}
                    refinput="email"

                />
                
                <Input
                    placeholder="Adresse d’entreprise"
                    value={this.props.company_address}
                    onChange={this.props.onChangeCompanyAddress}
                    refinput="company_address"

                />

                <div className="row width center">

                    <Input
                        placeholder="Nom du contact"
                        value={this.props.lastname}
                        onChange={this.props.onChangeLastname}
                        refinput="lastname"
                        width={this.props.width_name_input}
                        width_input={this.props.width_input}
                    />
                    <Input
                        placeholder="Prénom du contact"
                        value={this.props.firstname}
                        onChange={this.props.onChangeFirstname}
                        refinput="firstname"
                        width={this.props.width_name_input}
                        width_input={this.props.width_input}
                    />
                </div>

                <Input
                    placeholder="Mot de passe"
                    value={this.props.password}
                    onChange={this.props.onChangePassword}

                    refinput="password"
                    type="password"
                />
                <Input
                    placeholder="Confirmation de votre mot de passe"
                    value={this.props.password_confirm}
                    onChange={this.props.onChangePasswordConfirm}

                    refinput="password_confirm"
                    type="password"
                />
                <Button
                    text="Je m’inscris !"
                    //  width={this.props.width_register_button}
                    onClick={this.props.register}

                />
                <div className="width" style={{ height: '.5vmax', marginTop: 20 }}>
                    <div className="line" />
                </div>
                <p className="text width font-size black"
                    onClick={this.props.login}
                >
                    Vous avez déjà un compte ? <br /><span className="green" style={{ textDecoration: 'underline' }}>Connectez-vous ici</span>
                </p>
            </div>
        )
    }
}

export default RegisterPro;