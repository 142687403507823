import * as React from "react"

function SvgDown(props) {
    return (
        <svg width={10} height={10} viewBox="0 0 12.944 7.401" {...props}>
            <path
                data-name="Icon ionic-ios-arrow-down"
                d="M6.474 5.17l4.894-4.9a.921.921 0 011.306 0 .933.933 0 010 1.31L7.128 7.129a.923.923 0 01-1.276.027L.269 1.586A.925.925 0 011.575.276z"
                fill="#737b91"
           />
        </svg>
    )
}

export default SvgDown
