import * as React from "react"

function SvgPdf(props) {
    return (
        <svg width={'2vmax'} height={'2vmax'} viewBox="0 0 212.771 269.968" {...props}>
            <path
                data-name="Trac\xE9 1185"
                d="M144.492 0h-120.9a10.8 10.8 0 00-10.8 10.8v248.37a10.8 10.8 0 0010.8 10.8h165.58a10.8 10.8 0 0010.8-10.8V55.481z"
                fill="#e1e6e9"
            />
            <path
                data-name="Trac\xE9 1186"
                d="M144.493 55.482h55.481L144.494 0z"
                fill="#ebf0f3"
            />
            <path
                data-name="Trac\xE9 1187"
                d="M205.572 201.613H7.199a7.2 7.2 0 01-7.2-7.2v-63.38a7.2 7.2 0 017.2-7.2h198.373a7.2 7.2 0 017.2 7.2v63.38a7.2 7.2 0 01-7.2 7.2z"
                fill="#00b456"
            />
            <g data-name="Groupe 4109" fill="#e1e6e9">
                <path
                    data-name="Trac\xE9 1188"
                    d="M79.533 147.044q3.968 3.314 3.968 10.219t-4.052 10.111q-4.048 3.208-12.418 3.207h-6.742v11.144h-6.414v-38h13.046q8.647.001 12.612 3.319zm-4.539 15.682q1.931-2.041 1.929-5.981t-2.447-5.572q-2.449-1.629-7.666-1.63h-6.521v15.22h7.445q5.329 0 7.26-2.037z"
                />
                <path
                    data-name="Trac\xE9 1189"
                    d="M119.812 148.7q5.494 4.973 5.49 13.835t-5.326 14.023q-5.329 5.166-16.306 5.165H91.057v-38H104.1q10.222.003 15.712 4.977zm-.978 14q0-13.045-14.948-13.046h-6.415v26.038h7.122q6.9 0 10.573-3.289t3.668-9.706z"
                />
                <path
                    data-name="Trac\xE9 1190"
                    d="M139.981 149.706v10.436h16.85v5.926h-16.85v15.656h-6.415v-38h25.331l-.053 5.981h-18.863z"
                />
            </g>
        </svg>
    )
}

export default SvgPdf
