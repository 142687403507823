import * as React from "react"

function SvgSuccess(props) {
    return (
        <svg
            width="100px"
            height="100px"
            viewBox="0 0 102.434 102.569"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <g id="checked" transform="translate(-0.301 0)">
                <path
                    id="Trac\xE9_3428"
                    data-name="Trac\xE9 3428"
                    d="M91.556,93.483c-15.695,12.812-64.7,12.812-80.076,0-15.695-12.812-14.093-67.584,0-82s65.983-14.414,80.076,0S107.251,80.671,91.556,93.483Z"
                    transform="translate(0 -0.523)"
                    fill="#8fcbb1"
                />
                <g
                    id="Groupe_4258"
                    data-name="Groupe 4258"
                    transform="translate(0.348 0)"
                    opacity={0.2}
                >
                    <path
                        id="Trac\xE9_3429"
                        data-name="Trac\xE9 3429"
                        d="M52,70.539C37.906,81.75,22.211,87.516,7.477,87.836-3.413,69.258-1.171,24.1,11.641,10.963,23.172-.888,59.367-3.131,80.187,4.236,85.311,25.377,74.741,52.282,52,70.539Z"
                        transform="translate(-0.509 0)"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Trac\xE9_3430"
                    data-name="Trac\xE9 3430"
                    d="M108.023,158.382a6.849,6.849,0,0,1-5.765-2.883L84.321,132.438a7.3,7.3,0,0,1,11.531-8.968l12.171,15.695,26.906-34.913a7.3,7.3,0,0,1,11.531,8.968L113.788,155.5A8.378,8.378,0,0,1,108.023,158.382Z"
                    transform="translate(-63.872 -78.554)"
                    fill="#fff"
                />
            </g>
        </svg>
    )
}

export default SvgSuccess
