import * as React from "react"

function SvgRefresh(props) {
    return (
        <svg width={'15px'} height={'15px'} viewBox="0 0 27.306 25.976" {...props}>
            <g data-name="Groupe 4227">
                <g data-name="Groupe 4225">
                    <path
                        data-name="Trac\xE9 1423"
                        d="M24.278 11.508a.93.93 0 00-1.837.292v.017a10.242 10.242 0 11-2.543-5.2L15.779 7.99a.93.93 0 00-.589 1.176h0a.93.93 0 001.176.589h0l5.582-1.861a.93.93 0 00.636-.883V1.43a.93.93 0 10-1.861 0v3.4a11.972 11.972 0 103.554 6.68z"
                        fill="#00b456"
                        stroke="#00b456"
                    />
                    <path
                        data-name="Rectangle 1555"
                        fill="#fff"
                        d="M19.306 10.167h8v6h-8z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default SvgRefresh
