import * as React from "react"

function SvgClose(props) {
    return (
        <svg width={20} height={20} viewBox="0 0 655.35 655.336" {...props}>
            <path
                d="M387.715 327.673l255.18-255.188a42.452 42.452 0 00-60.036-60.036L327.671 267.637 72.491 12.449a42.452 42.452 0 00-60.036 60.036l255.18 255.188-255.18 255.191A42.452 42.452 0 1072.491 642.9l255.18-255.188 255.188 255.184a42.452 42.452 0 0060.036-60.032zm0 0"
                fill="#737b91"
            />
        </svg>
    )
}

export default SvgClose
