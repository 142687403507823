import React, { Component } from 'react';
import moment from 'moment';
import SvgPdf from '../assets/SvgPdf'




export default class BookingItem extends Component {
    constructor(props) {

        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({ width: window.innerWidth, height: window.innerHeight })
        });
    }
    render() {

        console.log(this.props.el)

        return (
            <tr>

                <td> {moment(this.props.date).format('DD-MM-YYYY')}  {moment(this.props.date).format('HH:mm')} </td>
                <td> {this.props.el.Passenger.is_professional ? this.props.el.UserProfessionalCollaborator.firstname + " " + this.props.el.UserProfessionalCollaborator.lastname : this.props.el.Passenger.firstname + " " + this.props.el.Passenger.lastname} </td>
                <td>{this.props.address_from}</td>
                <td>{this.props.address_to}</td>
                <td>{this.props.amount} €</td>
                <td>

                {
                        (() => {

                            if (this.props.status == 9) {
                                return "Annulée"
                            } else if (this.props.status == 2) {
                                return "En attente d'attribution"
                            } else if (this.props.status == 3) {
                                return "Validé"
                            } else if (this.props.status == 4) {
                                return "Terminée"
                            } else if (this.props.status == 31 || this.props.status == 32) {
                                return "En cours.."
                            }

                        })()

                    }

                </td>

            </tr>
           
        )

        // return (
        //     <div className="row center" style={{ width: (this.state.height > this.state.width) ? '85vmin' : '70vmax', backgroundColor: this.props.backgroundColor }}>
        //         <p className="text" style={{ maxWidth: (this.state.height > this.state.width) && '15vmin' }}> {moment(this.props.date).format('DD-MM-YYYY')}  {moment(this.props.date).format('HH:mm')}</p>
        //         <p className="list-text" style={{ maxWidth: (this.state.height > this.state.width) && '20vmin' }}>{this.props.address_from}</p>

        //         <p className="list-text" style={{ maxWidth: (this.state.height > this.state.width) && '20vmin' }}>{this.props.address_to}</p>

        //         <p className="list-text">{this.props.amount} €</p>

        //         <p className="list-text">
        //             {
        //                 (() => {
        //                     console.log(this.props)

        //                     if (this.props.status == 9) {
        //                         return "Annulée"
        //                     } else if (this.props.status == 2) {
        //                         return "En attente d'attribution"
        //                     } else if (this.props.status == 3) {
        //                         return "Validé"
        //                     } else if (this.props.status == 4) {
        //                         return "Terminée"
        //                     } else if (this.props.status == 31 || this.props.status == 32) {
        //                         return "En cours.."
        //                     }

        //                 })()

        //             }
        //         </p>
 
        //     </div>
        // )
    }

}

