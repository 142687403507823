import * as React from "react"

function SvgBike(props) {
    return (
        <svg width={'30px'} height={'50px'} viewBox="0 0 23.525 41.237" {...props}>
            <g data-name="Groupe 4179">
                <g data-name="Groupe 4139">
                    <g data-name="Groupe 4136" transform="translate(.976 9.689)">
                        <path
                            data-name="Trac\xE9 1398"
                            d="M21.268 3.365H1.281a1.281 1.281 0 010-2.562h19.987a1.281 1.281 0 010 2.562z"
                            fill="#414952"
                        />
                        <path
                            data-name="Trac\xE9 1399"
                            d="M21.267.803H11.245v2.562h10.027a1.281 1.281 0 000-2.562z"
                            fill="#665e68"
                        />
                        <path
                            data-name="Trac\xE9 1400"
                            d="M13.794 23.539H8.755a5.244 5.244 0 01-5.244-5.244V9.959a5.244 5.244 0 015.244-5.244h5.039a5.244 5.244 0 015.244 5.244v8.336a5.244 5.244 0 01-5.244 5.244z"
                            fill="#00a96c"
                        />
                        <path
                            data-name="Trac\xE9 1401"
                            d="M13.793 4.714h-2.548v18.825h2.553a5.244 5.244 0 005.244-5.244V9.959a5.244 5.244 0 00-5.249-5.245z"
                            fill="#43cb8e"
                        />
                        <path
                            data-name="Trac\xE9 1402"
                            d="M9.737 4.903h3.075V19.97H9.737z"
                            fill="#b1dbfc"
                        />
                        <path
                            data-name="Trac\xE9 1403"
                            d="M11.245 4.903h1.572V19.97h-1.572z"
                            fill="#dfebfa"
                        />
                        <path
                            data-name="Trac\xE9 1404"
                            d="M11.276 31.548a3.7 3.7 0 01-3.7-3.7v-6.452a3.7 3.7 0 117.4 0v6.452a3.7 3.7 0 01-3.7 3.7z"
                            fill="#544e55"
                        />
                        <path
                            data-name="Trac\xE9 1405"
                            d="M11.274 17.7h-.029v13.846h.034a3.7 3.7 0 003.7-3.7v-6.452a3.7 3.7 0 00-3.705-3.694z"
                            fill="#665e68"
                        />
                        <path
                            data-name="Trac\xE9 1406"
                            d="M16.251 21.315a4.978 4.978 0 00-9.955 0v2.645h9.955z"
                            fill="#00ba66"
                        />
                        <path
                            data-name="Trac\xE9 1407"
                            d="M16.252 21.315a4.978 4.978 0 00-4.978-4.978h-.029v7.622h5.012z"
                            fill="#07c46e"
                        />
                        <path
                            data-name="Trac\xE9 1408"
                            d="M19.405 4.988l-8.131 1.025-8.131-1.025V0h16.262z"
                            fill="#00ba66"
                        />
                        <path
                            data-name="Trac\xE9 1409"
                            d="M11.245 0v6.009h.034l8.131-1.025V0z"
                            fill="#07c46e"
                        />
                        <circle
                            data-name="Ellipse 349"
                            cx={2.399}
                            cy={2.399}
                            transform="translate(8.822 1.292)"
                            fill="#ffd301"
                            r={2.399}
                        />
                        <path
                            data-name="Trac\xE9 1412"
                            d="M11.221 3.691a2.4 2.4 0 011.2-2.075 2.4 2.4 0 100 4.15 2.4 2.4 0 01-1.2-2.075z"
                            fill="#ffc20c"
                        />
                    </g>
                    <g data-name="Groupe 4120">
                        <g data-name="Groupe 4119">
                            <circle
                                data-name="Ellipse 347"
                                cx={6.718}
                                cy={6.718}
                                r={6.718}
                                fill="#ffc964"
                            />
                            <path
                                data-name="Trac\xE9 1334"
                                d="M1.866 6.718A6.719 6.719 0 017.65.066a6.718 6.718 0 100 13.3 6.719 6.719 0 01-5.784-6.648z"
                                fill="#f0b952"
                            />
                            <path
                                data-name="Trac\xE9 1335"
                                d="M6.714 8.846a.589.589 0 00-.589.589v3.974a6.736 6.736 0 001.178 0V9.435a.589.589 0 00-.589-.589z"
                                fill="#dfebfa"
                            />
                            <g data-name="Groupe 4114">
                                <path
                                    data-name="Trac\xE9 1336"
                                    d="M8.265 6.386a.589.589 0 01-.589-.589V3.55a.589.589 0 111.178 0v2.247a.589.589 0 01-.589.589z"
                                    fill="#dfebfa"
                                />
                            </g>
                            <g data-name="Groupe 4115">
                                <path
                                    data-name="Trac\xE9 1337"
                                    d="M5.171 6.386a.589.589 0 01-.589-.589V3.55a.589.589 0 111.178 0v2.247a.589.589 0 01-.589.589z"
                                    fill="#dfebfa"
                                />
                            </g>
                            <path
                                data-name="Trac\xE9 1338"
                                d="M8.757 5.621H4.68a.7.7 0 00-.7.7v1.671a2.183 2.183 0 002.177 2.177h1.122a2.183 2.183 0 002.177-2.177V6.32a.7.7 0 00-.699-.699z"
                                fill="#665e68"
                            />
                            <path
                                data-name="Trac\xE9 1339"
                                d="M5.85 7.991V6.32a.7.7 0 01.7-.7H4.68a.7.7 0 00-.7.7v1.671a2.183 2.183 0 002.177 2.177h1.122a2.166 2.166 0 00.374-.033A2.184 2.184 0 015.85 7.991z"
                                fill="#554e56"
                            />
                            <g data-name="Groupe 4116">
                                <path
                                    data-name="Trac\xE9 1340"
                                    d="M6.834 8.221h-.232a.589.589 0 110-1.178h.232a.589.589 0 110 1.178z"
                                    fill="#7b6c79"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SvgBike
