
import React, { Component } from 'react';
import './App.css';
import Button from './Components/Button'
import Input from './Components/Input'
import DatePicker, { registerLocale } from "react-datepicker";
import Fr from "date-fns/locale/fr";
import WebServices from './WebServices'
import "react-datepicker/dist/react-datepicker.css";
import Address from './Components/Address'
import swal from '@sweetalert/with-react'
import Summary from './Components/Summary'
import moment from 'moment';
import fr from 'moment/locale/fr';
import StripeHandler from './StripeHandler'
import CheckButton from './Components/CheckButton'
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';
import SvgRefresh from './assets/SvgRefresh'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import MyBookings from './Components/MyBookings'

import SuccessBooking from './Components/SuccessBooking'

import SvgDown from './assets/SvgDown'
import SvgCar from './assets/SvgCar'
import SvgClose from './assets/SvgClose'
import SvgBike from './assets/SvgBike'
import SvgTick from './assets/SvgTick'

import RegisterPro from './Components/RegisterPro'
import Register from './Components/Register'
import Login from './Components/Login'


import SvgCollaborators from './assets/SvgCollaborators'
import Select from 'react-select'
import SvgCompany from './assets/SvgCompany'

import Modal from 'react-modal';
import FadeIn from 'react-fade-in';

import env from './env'

var stripe = require('stripe-client')(env.stripe);

class App extends Component {
    constructor(props) {

        super(props);

        let today = moment().add(5, 'hours').add(3, 'days').toDate(),
            tomorrow = moment().add(6, 'hours').add(4, 'days').add(30, 'minutes').toDate()

        if (today.getHours() >= 20 || today.getHours() <= 8) {
            today.setHours(11)
            tomorrow.setHours(11)
        }

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            address_from: "",

            address_to: "",

            date: today,
            return_date: tomorrow,

            address_from_focused: false,
            address_to_focused: false,

            search: [],
            search_return: [],

            show: false,
            show_return: false,

            email: '',
            password: '',
            show_modal: false,
            lastname: '',
            firstname: '',
            password_confirm: '',


            cvc: '',
            expiry: '',
            focus: '',

            name: '',
            number: '',
            baggages: 0,

            is_one_way: 0,

            card: null,
            webview: false,
            amount: 0,

            amount_to_pay_bike: 0, 
            amount_to_pay_car: 0, 
            vehicle_selected: null,

            company: false,
            company_name: "",
            company_address: "",

            my_cards: [],

            companies: [],
            collaborators: [],
            collaborator: null,
            lastname_pro: "",
            firstname_pro: "",
            email_pro: "",
            phone_pro: "",
            addCollaborator: false,
            email_company: "",
            phone_company: "",
            addCompany: false,
            selected_company: null,

            google_place_from: "",
            google_place_to: "",
            duration: 0,
            coords: null,
            ticket_number: null,
            selected_card: null,
            show_loader: false,
            show_message: false,
            create_card: false,
            trip: null,
            show_add_card: false,
            mybookings: null,
            show_bookings: false,
            show_summary: false,
            checked: [],
            accept: false,
            calculate_loader: false,

            history_id: null,

            passenger_name: '',
            passenger_phone_number: '',
            passenger_email: '',


            showVoucherInput: false,
            voucher: '',
            voucherCode: null,

            show_order_form: true,

            successBooking: false,

            user: null,

            show_login: false,
            show_register_pro: false,

            showMyReservations: false,

        }

        moment.updateLocale('fr', fr);
        registerLocale("Fr", Fr);

    }

    componentDidMount() {

        this.getMyBookings()

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let step = params.get('step');

        WebServices.GetUserInformations().then((user) => {
            if (user.status === 200) {
                this.setState({ user: user.data })
            }
        })

        if (step == "2") {

            let booking = (JSON.parse(localStorage.getItem('booking')))

            this.setState({

                show_order_form: false,

                history_id: booking.history_id,
                
                date: new Date(booking.date),
                return_date: new Date(booking.return_date),

                baggages: booking.baggages,

                is_one_way: booking.is_one_way,

                address_from: booking.address_from,
                address_to: booking.address_to,

                duration_car: booking.duration_car,
                duration_bike: booking.duration_bike,

                amount_to_pay_bike: booking.amount_to_pay_bike,
                amount_to_pay_car: booking.amount_to_pay_car,

                vehicle_type_id: booking.vehicle_type_id,
                vehicle_selected: booking.vehicle_type_id,

                distance: booking.distance,
            })

            

            if (localStorage.getItem('token') == null) {
                this.setState({ show_modal: true, show_login: true })
            } else {
                this.getAllCards()
                this.getMyBookings()
                this.getMyCompanies()
                this.getMyCollaborators()
            }

        }

        window.addEventListener("resize", () => {

            if (!this.state.address_from_focused && !this.state.address_to_focused) {
                this.setState({ width: window.innerWidth, height: window.innerHeight })
            }

            this.sendNewSize()

        });

        window.addEventListener('message', (e) => {

            if (e.data.type === "loaded") {
                this.setState({ show_loader: false })
            }

            if (e.data === "Etherial::EZEE-S::Success") {

                if (this.state.create_card) {

                    WebServices.getCard({ id: this.state.card.id }).then((res) => {
                        this.setState({ webview: false, create_card: false, show_loader: false, show_message: false })
                        if (res.status === 200) {

                            if (res.data.active === true) {
                                this.getAllCards()
                                swal("Votre carte a été bien enregistrée !", "", "success")
                            } else {
                                swal("Une erreur s'est produite !", "", "error")
                            }
                        }
                    })

                } else {

                   this.updateTrip()
                   this.setState({ webview: false })

                }


            }

        });

        setTimeout(() => {
            this.sendNewSize()
        })

        setInterval(() => {
            this.sendNewSize()
        }, 1000)

    }

    sendNewSize() {

        window.parent.postMessage({
            "name": "resize",
            "scrollWidth": window.document.body.scrollWidth,
            "scrollHeight": window.document.body.scrollHeight,
        }, "*");

    }

    componentWillUnmount() {
        window.removeEventListener('resize');
    }

    resetForm() {

        this.setState({
            address_from: "",
            address_to: "",
            baggages: null,
            amount_to_pay_bike: 0, 
            amount_to_pay_car: 0, 
            vehicle_selected: null,

        })
    }

    login() {
        this.setState({ show_loader: true })
        WebServices.login({
            email: this.state.email,
            password: this.state.password
        }).then((res) => {
            
            if (res.status === 200) {
                WebServices.token = res.data.token
                WebServices.setToken(res.data.token)
                this.getAllCards()
                this.getMyBookings()
                this.getMyCompanies()
                this.getMyCollaborators()
                this.setState({ show_loader: false, show_modal: false })

                WebServices.GetUserInformations().then((user) => {
                    if (user.status === 200) {
                        this.setState({ user: user.data })
                    }
                })
                // swal("Vous êtes connecté !", " ", "success")
            } else {
                this.setState({ show_loader: false })
                swal("", "Vos identifiants sont invalides", "error");
            }
        });
    }

    checkItem = (item, index) => {
        const { checked } = this.state;

        if (!checked.includes(index)) {
            this.setState({ checked: [index], selected_card: item.id });
            let card = item;
            card.rowID = index;
        } else {
            this.setState({ checked: checked.filter((a) => a !== index) });
        }
    };

    getMyBookings() {

        WebServices.getMyBookings().then((res) => {
            if (res.status === 200) {
                this.setState({ mybookings: res.data })
            }
        });
    }
    getMyCompanies() {
        WebServices.getAllCompanies().then((res) => {

            let companies = res.map((el) => {
                return {
                    label: el.name,
                    value: { name: el.name, id: el.id },
                }
            })
            this.setState({ companies: companies })


        });
    }
    getMyCollaborators() {
        WebServices.getAllCollaborators().then((res) => {

            let collaborators = res.map((el) => {
                return {
                    label: el.firstname + ' ' + el.lastname,
                    value: { name: el.firstname + ' ' + el.lastname, id: el.id },
                }
            })

            this.setState({ collaborators: collaborators })


        });
    }

    getAllCards() {
        WebServices.getAllMyCards().then((res) => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    this.setState({ checked: [0], selected_card: res.data[0].id });
                }

                this.setState({ my_cards: res.data })
            }
        })
    }

    closeModal() {

        let params = new URLSearchParams(window.location.search);
        let step = params.get('step');

        if (step == 2) {
            if (this.state.user !== null) {
                this.setState({ show_modal: false, showMyReservations: false })
            }
        } else {
            this.setState({ show_modal: false, showMyReservations: false })
        }

    }

    register() {
        this.setState({ show_loader: true })
        let user = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone_number: this.state.phone_number,
            password: this.state.password,
            password_verif: this.state.password_confirm
        };

        if (this.state.show_register_pro) {
            user.professional_name = this.state.company_name
            user.address = this.state.company_address
        }

        WebServices.register(user).then((res) => {

            if (res.status === 201) {
            

                WebServices.login(user).then((res) => {
                    if (res.status === 200) {
                        WebServices.token = res.data.token
                        WebServices.setToken(res.data.token)
                        this.getAllCards()
                        this.getMyBookings()
                        this.getMyCompanies()
                        this.getMyCollaborators()
                        this.setState({ show_login: false, show_loader: false, show_modal: false })
                        WebServices.GetUserInformations().then((user) => {
                            if (user.status === 200) {
                                this.setState({ user: user.data })
                            }
                        })
                    }
                });
            } else {
                this.setState({ show_loader: false })
                swal("", WebServices.handleErrors(res.errors), "error");
            }

        });
    }

    logout() {

        WebServices.token = null

        WebServices.setToken(null)

        localStorage.removeItem('token')

        this.setState({ user: null })

    }


    updateTrip() {
        
        WebServices.updateTrip({ status: 2 }, this.state.trip.id).then((res) => {
            if (res.status === 202) {
                this.setState({successBooking: true})
            } else {
                swal("Une erreur s'est produite !", "", "error")
            }
        });
    }

    onLocationChange(event) {

        this.setState({ amount_to_pay_bike: 0, amount_to_pay_car: 0, vehicle_selected: null })
        let text = event.target.value

        if (this.state.address_from_focused) {
            this.setState({ address_from: text });
        } else {
            this.setState({ address_to: text });
        }

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            this.finishTyping(text);
        }, 200);


    }


    finishTyping(text) {
        WebServices.getKeyWordPlaces({ keyword: text }).then((res) => {
            if (res.status === 200) {
                if (this.state.address_from_focused) {
                    this.setState({ search: res.data, show: true, show_return: false });
                } else {
                    this.setState({ search_return: res.data, show_return: true, show: false });
                }
            }
        });
    }


    getPoints(address) {

        this.setState({ amount_to_pay_bike: 0, amount_to_pay_car: 0, vehicle_selected: null }, () => {
            if (this.state.address_from_focused) {
                this.setState({ show: false, address_from: address.formatted_address, google_place_from: address.type })
            } else {
                this.setState({ show_return: false, address_to: address.formatted_address, google_place_to: address.type })
            }
        })

    }

    getPricing() {
        this.setState({ calculate_loader: true })

        if (this.state.address_from.length > 0 && this.state.address_to.length) {
            WebServices.getTripPricing(this.state.google_place_from, this.state.google_place_to, this.state.is_one_way == 0 ? true : false).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        amount_to_pay_bike: res.data[0].amount_to_pay > 0 ? res.data[0].amount_to_pay / 100 : res.data[0].amount_to_pay,
                        amount_to_pay_car: res.data[1].amount_to_pay > 0 ? res.data[1].amount_to_pay / 100 : res.data[1].amount_to_pay,
                        duration_bike: (parseInt(res.data[0].duration) / 60).toFixed(0),
                        duration_car: (parseInt(res.data[1].duration) / 60).toFixed(0),
                        history_id: res.data[0].history_id,
                        calculate_loader: false,
                        distance: (parseInt(res.data[0].distance / 1000)).toFixed(1)
                    })
                } else {
                    this.setState({ calculate_loader: false })
                    swal("Une erreur s'est produite !", "", "error")
                }
            })
        }

    }

    confirm() {
        this.setState({ show_loader: true })
        

        let trip = {
            card_id: this.state.selected_card,
            history_id: this.state.history_id,
            voucher_id: this.state.voucherCode ? this.state.voucherCode.id : null,
            vehicle_type_id: this.state.vehicle_selected,
            is_one_way: this.state.is_one_way === 0 ? true : false,
            baggage: this.state.baggages === 2 ? true : false,
            trip_date: this.state.date.toISOString(),
            return_date: this.state.is_one_way === 0 ? null : this.state.return_date.toISOString(),
            comment: this.state.comment,
        }

        if (this.state.user.is_professional) {
            trip.user_professional_collaborator_id = this.state.collaborator ? this.state.collaborator.value.id : null
        } else {
            trip.user_company_id = this.state.selected_company ? this.state.selected_company.value.id : null
        }

        WebServices.createTrip(trip).then((res) => {

            if (res.status === 200) {

                this.setState({ trip: res.data, show_modal: false, show_loader: false })

                if (res.data.status != "requires_capture") {
                    this.setState({ webview: true, url: res.data.url })
                } else {
                    this.getMyBookings()
                    this.updateTrip()
                   
                }

            } else {
                this.setState({ show_modal: false, show_loader: false })
                swal("Une erreur s'est produite !", "", "error")
            }

        });


    }

    createCard() {

        this.setState({ show_loader: true, show_message: true, create_card: true })

        let date = this.state.expiry.split('/');

        stripe.createToken({
            card: {
                number: this.state.number.split(' ').join(''),
                exp_month: parseInt(date[0]),
                exp_year: parseInt(date[1]),
                cvc: this.state.cvc
            }
        }).then((stripeTokenInfo) => {
            stripeTokenInfo.json().then((data) => {
                let stripe_source = { stripe_source: data.id };
                if (data.error) {
                    this.setState({ show_loader: false, show_message: false })
                    swal("Une erreur s'est produite !", `${StripeHandler(data.error.code)}`, "error")
                } else {
                    // resolve(stripe_source)

                    WebServices.createCard(stripe_source).then((res) => {
                        if (res.status === 201) {
                            this.setState({ card: res.data, selected_card: res.data.id, show_loader: false, show_message: false, create_card: false, show_add_card: false });
                            this.getAllCards()
                            swal("Votre carte a été bien enregistrée !", "", "success")
                        } else if (res.status === 203) {
                            setTimeout(() => {
                                this.setState({ webview: true, url: res.data.setupIntent.redirect_to_url.url, card: res.data.card, selected_card: res.data.id, show_message: false, show_add_card: false })
                            }, 500)
                        } else {
                            this.setState({ show_loader: false, show_message: false, create_card: false })
                            swal("Une erreur s'est produite !", "", "error")
                        }
                    });

                }
            })
        });



    }

    goToPricing() {

        localStorage.setItem("booking", JSON.stringify({
            history_id: this.state.history_id,
            
            date: this.state.date,
            return_date: this.state.return_date,

            baggages: this.state.baggages,

            is_one_way: this.state.is_one_way,

            address_from: this.state.address_from,
            address_to: this.state.address_to,

            duration_car: this.state.duration_car,
            duration_bike: this.state.duration_bike,

            amount_to_pay_bike: this.state.amount_to_pay_bike,
            amount_to_pay_car: this.state.amount_to_pay_car,

            vehicle_type_id: this.state.vehicle_selected,

            distance: this.state.distance,


        }))

        this.setState({
            show_loader: true
        })

        setTimeout(() => {
            window.parent.postMessage("change-page-to-pricing", "*")
        }, 100)

    }

    createCollaborator() {
        this.setState({ show_loader: true })

        WebServices.createCollaborator({
            firstname: this.state.firstname_pro,
            lastname: this.state.lastname_pro,
            phone: this.state.phone_pro,
            email: this.state.email_pro,
        }).then((res) => {
            if (res.status === 201) {
                this.getMyCollaborators()
                
                this.setState({ show_loader: false, addCollaborator: !this.state.addCollaborator})
                swal("Votre invité a été bien enregistrée !", "", "success")

            } else {
                this.setState({ show_loader: false })
                swal("Une erreur s'est produite !", "", "error")
            }
        })


    }

    createCompany() {
        this.setState({ show_loader: true })
        WebServices.createCompany({
            name: this.state.company_name,
            address: this.state.company_address,
            phone: this.state.phone_company,
            email: this.state.email_company,
        }).then((res) => {
            if (res.status === 201) {
                this.getMyCompanies()
                this.setState({ show_loader: false, addCompany: !this.state.addCompany})
                swal("Votre société a été bien enregistrée !", "", "success")
            } else {
                this.setState({ show_loader: false })
                swal("Une erreur s'est produite !", "", "error")
            }
        })
    }

    addVoucher() {
        this.setState({ show_loader: true })
        WebServices.Vouchers(this.state.voucher).then((res) => {
            this.setState({ show_loader: false })
            if (res.status === 200) {
                if (res.data.already_use) {
                    swal("Erreur", "Ce code promo est déjà utilisé !", "error")
                } else {
                    this.setState({
                        voucherCode: res.data.voucher,

                    })
                }
            } else {
                swal("Erreur", "Le code promo saisi n'existe pas", "error")
            }
        });
    }

    render() {

        

        const customStyles = {
            overlay: { backgroundColor: 'rgba(0,0,0,0.75)' },
            content: {
                // overflow: 'hidden',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 99999,
                boxShadow: ".1px .1px 2px 2px rgba(0, 0, 0, .2)",
                backgroundColor: 'white',
                border: 0,
                paddingTop: 20,
                borderRadius: 10,
                maxHeight: '90%'
            }
        };

        if (this.state.successBooking) {
            return <SuccessBooking />
        }

        if (this.state.webview) {
            return (
                <div className="App" >
                    <iframe
                        id="iframe_id"
                        className="iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ width: "100%", height: '100%', }}
                        src={this.state.url}
                    >
                    </iframe>
                </div>)
        } else {
            return (

                <div
                    className="App"
                    onClick={() => this.setState({ show: false })}
                    style={{ 'max-width': ('1240px') }}
                >

                    {this.state.show_loader && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}

                    {

                        (() => {

                            if (this.state.show_order_form) {

                                return (
                                    <div className="big-wrapper">

                                        <div className="elementor-element elementor-element-5a5fa8d elementor-column elementor-col-50 elementor-top-column" data-id="5a5fa8d" data-element_type="column" style={{
                                            "align-content": "center",
                                            "align-items": "center",
                                        }}>

                                        <div class="elementor-column-wrap elementor-element-populated">
                                                        <div class="elementor-widget-wrap">
                                                    <div class="elementor-element elementor-element-48b36e4 elementor-widget elementor-widget-heading" data-id="48b36e4" data-element_type="widget" data-widget_type="heading.default">
                                            <div class="elementor-widget-container">
                                        <h1 class="elementor-heading-title elementor-size-default center-on-mobile" style={{
                                            "color": "#000",
                                            "font-family": '"Poppins", Sans-serif',
                                            "font-size": "62px",
                                            "font-weight": "bold",
                                            "line-height": "1.4em",
                                            "letter-spacing": "1px",
                                        }}>Ezee,<br />pour vous et pour la planète !</h1>		</div>

                                        
                                            </div>
                                            <div class="elementor-element elementor-element-b12a1c6 elementor-widget elementor-widget-text-editor" data-id="b12a1c6" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-text-editor elementor-clearfix center-on-mobile"><p>Commandez maintenant votre scooter électrique avec chauffeur</p><p>et déplacez-vous rapidement sur la région parisienne tout en gardant l'esprit GREEN.</p><p>Laissez-vous transporter ...</p></div>
                                            </div>
                                            </div>
                                                    </div>
                                                </div>

                                        
                                        </div>

                                        <div className="booking-block">

                                            {this.state.calculate_loader && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}

                                            <p className="title" style={{
                                                marginBottom: 5,
                                                "color": "#3F3A64",
                                                "font-size": "20px",
                                                "font-weight": "bold"
                                            }}>Réservation en ligne</p>

                                            {/* <p className="text" style={{ color: '#8C89A2', 'font-size': '15px' }} > Calculez et réservez à l’avance votre trajet en ligne en toute sécurité avec WrooMyDriver</p> */}

                                            {this.state.user &&
                                                <>Hello, <span className="title" style={{ fontSize: 14, margin: 0, color: "#00b456" }}> {`${this.state.user.professional_name ? this.state.user.professional_name : this.state.user.firstname + ' ' + this.state.user.lastname}`} </span> <br/> 
                                                    <span style={{ 'font-size': '12px', cursor: 'pointer', color: '#00b456' }} onClick={() => { this.setState({ showMyReservations: true, show_modal: true }) }} >Voir mes réservations</span>
                                                    <span style={{ 'font-size': '12px', cursor: 'pointer', marginTop: 5 }} onClick={() => { return this.logout()  } }> [x] Se deconnecter</span>

                                                </>
                                            }

                                            {!this.state.user &&
                                                <span style={{ 'font-size': '12px', cursor: 'pointer', color: "#00b456" }} onClick={() => { this.setState({ show_login: true, show_modal: true }) }}> Se connecter</span>
                                            }
                                            
                                            <p className="text" >Calculez, réservez et payez votre trajet en ligne<br />
                                                en toute sécurité</p>
                                                
                                            <div className="booking">

                                                <Input
                                                    placeholder={"Adresse de départ"}
                                                    value={this.state.address_from}
                                                    onChange={this.onLocationChange.bind(this)}
                                                    refinput="address_from_ref"
                                                    onFocus={() => {
                                                        this.setState({ address_from_focused: true, address_to_focused: false, show_return: false }, () => {

                                                            if (this.state.search.length > 0) {
                                                                setTimeout(() => {
                                                                    this.setState({ show: true })
                                                                }, 100)

                                                            }

                                                        })

                                                    }}
                                                    onBlur={() => {
                                                        this.setState({ show: false })
                                                    }}
                                                />

                                                {this.state.search.length > 0 && this.state.show &&
                                                    <div className="list" style={{
                                                        // top: this.state.width < 1024 ? '12rem' : '16rem',
                                                        top: '17%',
                                                        'boxShadow': 'rgba(0, 0, 0, 0.75) 0px 0px 10px 0px'
                                                    }}>
                                                        <p className="text">Veuillez séléctionner une adresse </p>
                                                        {this.state.search.map((el) =>
                                                            <Address
                                                                address={el.formatted_address}
                                                                address_width={(this.state.height > this.state.width) ? '58vmin' : '20vmax'}
                                                                onClick={() => this.getPoints(el)}
                                                            />
                                                        )}
                                                    </div>
                                                }

                                                <Input
                                                    placeholder="Adresse d'arrivée"
                                                    value={this.state.address_to}
                                                    onChange={this.onLocationChange.bind(this)}
                                                    refinput="address_to_ref"
                                                    onFocus={() => {
                                                        this.setState({ address_from_focused: false, address_to_focused: true, show: false }, () => {

                                                            if (this.state.search_return.length > 0) {
                                                                setTimeout(() => {
                                                                    this.setState({ show_return: true })
                                                                }, 100)

                                                            }

                                                        })

                                                    }}
                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}

                                                />

                                                {this.state.search_return.length > 0 && this.state.show_return &&
                                                    <div className="list" style={{
                                                        top: '34%',
                                                        'boxShadow': 'rgba(0, 0, 0, 0.75) 0px 0px 10px 0px'
                                                    }}>
                                                        <p className="text">Veuillez séléctionner une adresse</p>
                                                        {this.state.search_return.map((el) =>
                                                            <Address
                                                                address={el.formatted_address}
                                                                address_width={(this.state.height > this.state.width) ? '58vmin' : '20vmax'}
                                                                onClick={() => this.getPoints(el)}
                                                            />
                                                        )}
                                                    </div>
                                                }

                                                {
                                                this.state.google_place_from.length > 0 && this.state.google_place_from === this.state.google_place_to &&
                                                    <div className="flex width" style={{ alignItems: "flex-end" }}>
                                                        <p className="text" style={{ color: 'red' }}>Même adresse attribuée deux fois</p>
                                                    </div>
                                                }


                                                <Row className="flex width" style={{  }}>

                                                    <Col className="flex" style={{  width: '98%' }} >

                                                        <DatePicker
                                                            locale="Fr"
                                                            selected={this.state.date}
                                                            style={{backgroundColor:'green'}}
                                                            timeIntervals={15}
                                                            showTimeSelect
                                                            minDate={moment().toDate()}
                                                            minTime={(moment()).hours(8).minutes(0).toDate()}
                                                            maxTime={(moment()).hours(20).minutes(0).toDate()}
                                                            timeCaption="Horaire"
                                                            filterTime={(time) => {

                                                                const currentDate = new Date();
                                                                const selectedDate = new Date(time);
                                                                const selectedDate2 = moment(selectedDate)

                                                                var hour = new Date().getHours();

                                                                let found = false

                                                                if (currentDate.getTime() < selectedDate.getTime()) {
                                                                    
                                                                    for (let index = 0; index < 180; index++) {
                                                                        if (moment().seconds(0).milliseconds(0).add(index, 'minutes').isSame(selectedDate2)) {
                                                                            found = true
                                                                        }
                                                                    }

                                                                    if (hour >= 16 || hour <= 9) {

                                                                        for (let index = 0; index < 120; index++) {
                                                                            if ( moment().seconds(0).milliseconds(0).hour(8).minutes(0).add(index, 'minutes').isSame(selectedDate2)) {
                                                                                found = true
                                                                            }
                                                                        }

                                                                    }

                                                                   return !found

                                                                } else {

                                                                    return false
                                                                }

                                                            }}
                                                            onChange={(date) => {
                                                                this.setState({ date: date })
                                                            }}
                                                            className="date-picker"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                        />
                                                    </Col>
                                                  
                                                </Row>

                                                <div className="width select-wrapper">
                                                <select id="Aller" className="select" onChange={(e) => this.setState({ is_one_way: e.target.value, amount_to_pay_bike: 0, amount_to_pay_car: 0 })} value={this.state.is_one_way}>
                                                    <option value="0">Aller simple</option>
                                                    <option value="1">Aller retour</option>
                                                </select>
                                                <SvgDown />
                                            </div>

                                            {this.state.is_one_way == 1 &&
                                                <Row className="flex width" style={{  }}>

                                                    <Col className="flex" style={{  width: '98%' }} >

                                                        
                                                        <DatePicker
                                                            locale="Fr"
                                                            selected={this.state.return_date}
                                                            style={{backgroundColor:'green'}}
                                                            timeIntervals={15}
                                                            showTimeSelect
                                                            timeCaption="Horaire"
                                                            minDate={this.state.date}
                                                            minTime={(moment()).hours(8).minutes(0).toDate()}
                                                            maxTime={(moment()).hours(20).minutes(0).toDate()}
                                                            onChange={(date) => {
                                                                this.setState({ return_date: date })
                                                            }}
                                                            
                                                            className="date-picker"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                        />

                                                    </Col>
                                                  
                                                  </Row>}


                                                {(this.state.amount_to_pay_bike != 0 || this.state.amount_to_pay_car != 0) &&
                                                    <div className="row width price">
                                                        <button class="flex center" 
                                                            style={{ 
                                                                outline: 0, 
                                                                cursor: 'pointer', 
                                                                boxShadow: ".5px .5px 2px 2px rgba(0, 0, 0, .1)", 
                                                                width: '150px', 
                                                                height: '110px', 
                                                                borderRadius: 10, 
                                                                backgroundColor: this.state.amount_to_pay_bike < 0 ? '#CBD7E1' : 'white', 
                                                                border: this.state.vehicle_selected === 1 ? '2px solid #00B456' : 0, 
                                                                justifyContent: 'center', 
                                                                opacity: this.state.amount_to_pay_bike < 0 ? 0.5 : 1 
                                                            }}
                                                            onClick={() => { this.setState({ vehicle_selected: 1 }) && this.sendNewSize() }}
                                                            disabled={this.state.amount_to_pay_bike < 0}
                                                        >
                                                            <SvgBike />
                                                            <p className="text" style={{ margin: 0 }}>Moto électrique</p>

                                                            {this.state.amount_to_pay_bike > 0 &&
                                                                <p className="text" style={{ margin: 0 }}>Trajet : {this.state.duration_bike} min</p>
                                                            }
                                                            {/* <p className={this.state.amount_to_pay_bike < 0 ? "text" : "text-semibold"} style={{ margin: 0, color: this.state.amount_to_pay_bike < 0 ? "#CD0000" : "#00B456" }}>{this.state.amount_to_pay_bike < 0 ? "Non disponible pour ces zones" : this.state.amount_to_pay_bike.toFixed(2) + "€"}</p> */}

                                                            { this.state.amount_to_pay_bike === -1 && <p className="text" style={{ margin: 0, color: "#CD0000" }}>Non disponible pour ces zones</p> } 
                                                            { this.state.amount_to_pay_bike === -2 && <p className="text" style={{ margin: 0, color: "#00B456" }}>Bientôt disponible</p> }
                                                            { this.state.amount_to_pay_bike === -3 && <p className="text" style={{ margin: 0, color: "#00B456" }}>Non disponible pour ces horaires</p> }

                                                            { this.state.amount_to_pay_bike > 0 && <p className="text-semibold" style={{ margin: 0, color: "#00B456" }}>{this.state.amount_to_pay_bike.toFixed(2)} €</p> } 

                                                        </button>

                                                        <button class="flex center" 
                                                            style={{ 
                                                                outline: 0, 
                                                                cursor: 'pointer', 
                                                                boxShadow: ".5px .5px 2px 2px rgba(0, 0, 0, .1)",
                                                                width: '150px', 
                                                                height: '110px', 
                                                                borderRadius: 10, 
                                                                backgroundColor: this.state.amount_to_pay_car < 0  ? '#CBD7E1' : 'white', 
                                                                border: this.state.vehicle_selected === 2 ? '2px solid #00B456' : 0, 
                                                                justifyContent: 'center', 
                                                                opacity: this.state.amount_to_pay_car < 0  ? 0.5 : 1 
                                                            }}
                                                            onClick={() => { this.setState({ vehicle_selected: 2 }) &&  this.sendNewSize() }}
                                                            disabled={this.state.amount_to_pay_car < 0 }>
                                                            <SvgCar />
                                                            <p className="text" style={{ margin: 0 }}>Voiture électrique</p>

                                                            {this.state.amount_to_pay_car > 0 &&
                                                                <p className="text" style={{ margin: 0 }}>Trajet : {this.state.duration_car} min</p>
                                                            }

                                                            { this.state.amount_to_pay_car === -1 && <p className="text" style={{ margin: 0, color: "#CD0000" }}>Non disponible pour ces zones</p> } 
                                                            { this.state.amount_to_pay_car === -2 && <p className="text" style={{ margin: 0, color: "#00B456" }}>Bientôt disponible</p> } 
                                                            { this.state.amount_to_pay_car === -3 && <p className="text" style={{ margin: 0, color: "#00B456" }}>Non disponible pour ces horaires</p> }
                                                            { this.state.amount_to_pay_car > 0 && <p className="text-semibold" style={{ margin: 0, color: "#00B456" }}>{this.state.amount_to_pay_car.toFixed(2)} €</p> } 

                                                    
                                                        </button>

                                                    </div>
                                                }


                                                {(this.state.vehicle_selected === 1) &&
                                                    <div className="width select-wrapper">
                                                        <select id="baggage" className="select" onChange={(e) => this.setState({ baggages: e.target.value }) && this.sendNewSize()} value={this.state.baggages}>
                                                            <option value="0">{"Avez-vous un bagage Topcase?"}</option>
                                                            <option value="1" >Non</option>
                                                            <option value="2">Oui</option>
                                                        </select>
                                                        <SvgDown />
                                                    </div>
                                                }
                                                {this.state.vehicle_selected === 1 &&
                                                    <p className="text black" style={{ margin: 0, color: "#222222", fontSize: 12, textAlign: 'left', maxWidth: '80%', marginLeft: 10 }}>Votre bagage peut mesurer jusqu'à 56 x 37 x 24 cm maximum</p>
                                                }
                                                {(this.state.amount_to_pay_bike != 0 || this.state.amount_to_pay_car != 0) &&
                                                    <div className="width" style={{ alignItems: 'flex-start', marginTop: 10 }}>
                                                        <button className="row center" style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0, outline: 0 }}
                                                            onClick={() => {
                                                                this.getPricing()
                                                            }}>
                                                            <SvgRefresh />
                                                            <p className="text-medium black" style={{ margin: 0, color: "#222222", marginLeft: 5 }} onClick={() => this.sendNewSize()}>Recalculer la course</p>
                                                        </button>
                                                    </div>
                                                }
                                                <Button
                                                    text={"Calculez et Payez en ligne"}
                                                    onClick={() => (this.state.amount_to_pay_bike != 0 || this.state.amount_to_pay_car != 0) ? this.goToPricing() : this.getPricing()}
                                                    marginBottom={(this.state.amount_to_pay_bike != 0 || this.state.amount_to_pay_car != 0) ? 0 : 20}
                                                    disabled={(this.state.vehicle_selected === null && (this.state.amount_to_pay_bike != 0 || this.state.amount_to_pay_car != 0)) || this.state.google_place_from.length == 0 || this.state.google_place_to.length == 0 || this.state.google_place_to == this.state.google_place_from}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                )


                            } else { 

                                return (

                                <>

                                    <div className="summary ">

                                    <div
                                        className={(window.innerWidth <= 1024) ? "flex center" : "row"}
                                    // style={{ width: ((window.innerWidth > 1024)) && "65vmax" }}
                                    >


                                        <Summary
                                            date={this.state.date}
                                            return_date={this.state.return_date}
                                            address_from={this.state.address_from}
                                            address_to={this.state.address_to}
                                            distance={this.state.distance}
                                            duration={this.state.vehicle_selected === 1 ? this.state.duration_bike : this.state.duration_car}
                                            comment={this.state.comment}
                                            user={this.state.user || {}}
                                            is_one_way={this.state.is_one_way}
                                            onChangeComment={(e) => this.setState({ comment: e.target.value })}

                                            passenger_name={this.state.passenger_name}
                                            onPassengerNameChange={(value) => { this.setState({ passenger_name: value.target.value }) }}

                                            passenger_email={this.state.passenger_email}
                                            onPassengerEmailChange={(value) => { this.setState({ passenger_email: value.target.value }) }}

                                            passenger_phone_number={this.state.passenger_phone_number}
                                            onPassengerPhoneNumberChange={(value) => { this.setState({ passenger_phone_number: value.target.value }) }}

                                            booking_type={this.state.booking_type}

                                            onDisconnect={() => { 
                                                this.logout()
                                                this.setState({ show_login: true, show_modal: true })
                                            }}

                                        />

                                        <div style={{ marginLeft: 20 }} className="flex min-width-bloc">

                                            <div>
                                                <h2 className="text-semibold">{this.state.user && this.state.user.is_professional ? 'INVITES' : 'SOCIETES'}</h2>
                                                <div className="line " style={{ width: (this.state.height > this.state.width) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                                                {this.state.user && this.state.user.is_professional ?
                                                    <div>
                                                        <Select
                                                            options={this.state.collaborators}
                                                            placeholder='Choisissez un invité...'
                                                            value={this.state.collaborator}
                                                            onChange={(item) => this.setState({
                                                                collaborator: {
                                                                    label: item.value.name,
                                                                    value: { name: item.value.name, id: item.value.id },
                                                                }
                                                            })}
                                                        />
                                                        <div className="row  center " style={{ justifyContent: 'flex-start' }}>
                                                            <SvgCollaborators />
                                                            <p className="text  font-size green " style={{ marginLeft: 5, cursor: 'pointer' }}
                                                                onClick={() => this.setState({ addCollaborator: !this.state.addCollaborator })}
                                                            >
                                                                Ajouter un invité
                                                            </p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row  center " style={{ justifyContent: 'flex-start', marginTop: 20, marginBottom: 20 }}>
                                                        <CheckButton selected={this.state.company} onClick={() => this.setState({ company: !this.state.company })} />
                                                        <label className="text black font-size" style={{ color: "#222222", marginLeft: 5, cursor: 'pointer' }} onClick={() => this.setState({ company: !this.state.company })}>
                                                            Commander via ma société
                                                        </label>
                                                    </div>
                                                }
                                                {this.state.addCollaborator &&
                                                    <div>
                                                        <div className="row  center" style={{ width: ((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax' }}>
                                                            <Input
                                                                placeholder="Nom"
                                                                value={this.state.lastname_pro}
                                                                onChange={(event) => this.setState({ lastname_pro: event.target.value })}
                                                                width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "35vmin" : '14vmax'}
                                                                width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '34vmin' : "13vmax"}
                                                            />
                                                            <Input
                                                                placeholder="Prénom"
                                                                value={this.state.firstname_pro}
                                                                onChange={(event) => this.setState({ firstname_pro: event.target.value })}
                                                                width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "35vmin" : '14vmax'}
                                                                width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '34vmin' : "13vmax"}
                                                            />
                                                        </div>
                                                        <Input
                                                            placeholder="Email"
                                                            value={this.state.email_pro}
                                                            onChange={(event) => this.setState({ email_pro: event.target.value })}
                                                            width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                            width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                                                        />
                                                        <Input
                                                            placeholder="Téléphone"
                                                            value={this.state.phone_pro}
                                                            onChange={(event) => this.setState({ phone_pro: event.target.value })}
                                                            width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                            width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                                                        />
                                                        <Button
                                                            text={"Valider"}
                                                            onClick={this.createCollaborator.bind(this)}
                                                            width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                            marginBottom={20}
                                                            disabled={this.state.lastname_pro.length === 0 || this.state.firstname_pro.length === 0 || this.state.email_pro.length === 0 || this.state.phone_pro.length === 0}
                                                        />
                                                    </div>}
                                            </div>

                                            {this.state.company &&
                                                <div>
                                                    <Select
                                                        options={this.state.companies}
                                                        placeholder='Choisissez une entreprise...'
                                                        value={this.state.selected_company}
                                                        onChange={(item) => this.setState({
                                                            selected_company: {
                                                                label: item.value.name,
                                                                value: { name: item.value.name, id: item.value.id },
                                                            }
                                                        })}
                                                    />
                                                    <div className="row  center " style={{ justifyContent: 'flex-start' }}>
                                                        <SvgCompany />
                                                        <p className="text  font-size green " style={{ marginLeft: 5, cursor: 'pointer' }}
                                                            onClick={() => this.setState({ addCompany: !this.state.addCompany })}
                                                        >
                                                            Ajouter une société
                                                    </p>
                                                    </div>
                                                    {this.state.addCompany &&
                                                        <div>

                                                            <div className="row  center" style={{ width: ((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax' }}>
                                                                <Input
                                                                    placeholder="Nom de la société"
                                                                    value={this.state.company_name}
                                                                    onChange={(event) => this.setState({ company_name: event.target.value })}
                                                                    width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "35vmin" : '14vmax'}
                                                                    width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '34vmin' : "13vmax"}
                                                                />
                                                                <Input
                                                                    placeholder="Adresse de la société"
                                                                    value={this.state.company_address}
                                                                    onChange={(event) => this.setState({ company_address: event.target.value })}
                                                                    width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "35vmin" : '14vmax'}
                                                                    width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '34vmin' : "13vmax"}
                                                                />
                                                            </div>
                                                            <Input
                                                                placeholder="Email"
                                                                value={this.state.email_company}
                                                                onChange={(event) => this.setState({ email_company: event.target.value })}
                                                                width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                                width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                                                            />
                                                            <Input
                                                                placeholder="Téléphone"
                                                                value={this.state.phone_company}
                                                                onChange={(event) => this.setState({ phone_company: event.target.value })}
                                                                width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                                width_input={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                                                            />
                                                            <Button
                                                                text={"Valider"}
                                                                onClick={this.createCompany.bind(this)}
                                                                width={((this.state.height > this.state.width) || (window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                                                                marginBottom={20}
                                                                disabled={this.state.company_name.length === 0 || this.state.company_address.length === 0 || this.state.email_company.length === 0 || this.state.phone_company.length === 0}
                                                            />
                                                        </div>}
                                                </div>

                                            }

                                            <h2 className="text-semibold">VOTRE COMMANDE</h2>
                                            <div className="line " style={{ width: (window.innerWidth <= 1024) ? "50%" : "29vmax", marginBottom: 10 }} />
                                            <Row className="row " >
                                                <Col className="flex" md={4} sm={8} style={{ backgroundColor: 'rgba(0, 180, 86, .1)' }}>
                                                    <p className='text-medium' style={{paddingLeft: "15px", paddingRight: "15px"}}> Sous-total </p>
                                                    <p className='text-medium' style={{paddingLeft: "15px", paddingRight: "15px"}}>{this.state.voucherCode &&  `Promotion` }  </p>
                                                    <p className='text-medium' style={{paddingLeft: "15px", paddingRight: "15px"}}>Total</p>
                                                </Col>

                                                <Col className="flex flex-end-align">
                                                    <p className='text-semibold green font-size'>{this.state.vehicle_selected === 1 ? this.state.amount_to_pay_bike : this.state.amount_to_pay_car}€</p>
                                                    <p className='text-semibold green font-size'>

                                                    {this.state.voucherCode && 

                                                        (() => {

                                                            if (this.state.voucherCode.discount_type == 1) {
                                                                return `Code: ${this.state.voucherCode.code} -${this.state.voucherCode.amount / 100}€`
                                                            } else if (this.state.voucherCode.discount_type == 2) {
                                                                return `Code: ${this.state.voucherCode.code} -${this.state.voucherCode.amount}%`
                                                            }
                                                        })()

                                                    }
                                                        
                                                    </p>

                                                    {
                                                        (() => {
                                                            let amount = (this.state.vehicle_selected === 1 ? this.state.amount_to_pay_bike : this.state.amount_to_pay_car)

                                                            if (this.state.voucherCode) {

                                                                if (this.state.voucherCode.discount_type === 1) {
                                                                    amount = amount - this.state.voucherCode.amount / 100
                                                                } else if (this.state.voucherCode.discount_type === 2) {
                                                                    amount = amount - (amount * (this.state.voucherCode.amount / 100))
                                                                }

                                                            }

                                                            return (
                                                                <p className='text-semibold green font-size'>
                                                                    {amount.toFixed(2) + "€"}

                                                                    <span className="text font-size"> (Inclut</span> <span className="'title green font-size'">  { ( ((amount  / 1.10) - amount ) * - 1).toFixed(2) } € </span> 
                                                                    <span className="text font-size"> TVA)</span>
                                                                </p>
                                                            )

                                                        })()

                                                    }

                                                </Col>
                                            </Row>
                                            <div className="row summary-width center" style={{ backgroundColor: 'rgba(0, 180, 86, .1)', marginTop: ((window.innerWidth <= 1024) ? 10 : (window.innerWidth < 910)) ? 50 : 0, paddingLeft: 10 }}>
                                                <p className="text-medium" >Carte de paiement </p>
                                                <img src={require('./assets/card.png')} style={{ width: '100px', height: '40px' }} />
                                            </div>

                                            <br/>  

                                            

                                            <div style={{display: 'flex', alignItems: 'space-between', marginTop: '15px'}}>

                                                <div class="width flex" style={{
                                                    backgroundColor: 'rgb(249, 249, 249)', 
                                                    width: '50%', 
                                                    border:' 1px solid rgb(239, 239, 239)', 
                                                    borderRadius: '10px', 
                                                    outline: 'currentcolor none 0px', 
                                                    alignItems: 'center', 
                                                    justifyContent: 'center'
                                                    }} placeholder="Code promo">
                                                    <input 
                                                        type="text" 
                                                        class="inputStyle" 
                                                        style={{
                                                            width: '90%',
                                                            color: 'rgb(115, 123, 145)',
                                                            outline: 'currentcolor none 0px'
                                                        }}
                                                        placeholder="Code promo"
                                                        value={this.state.voucher}
                                                        onChange={(event) => this.setState({ voucher: event.target.value })}
                                                    />
                                                </div>

                                                <div class="width flex" style={{
                                                    width: '50%', 
                                                    alignItems: 'center', 
                                                    justifyContent: 'center'
                                                    }} placeholder="Code promo"> 
                                                        <button class="button" style={{
                                                            width: '90%',
                                                            borderRadius: '5px',
                                                            backgroundColor: '#00B456',
                                                            color: 'white',
                                                            alignSelf: 'right',
                                                            cursor: 'pointer',
                                                            outline: 'currentcolor none 0px',
                                                            borderWidth: '0px',
                                                            marginBottom: '0px',
                                                            fontFamily: 'Montserrat-Bold',
                                                            // marginTop: '10px'
                                                        }} 
                                                            onClick={this.addVoucher.bind(this)}
                                                            disabled={this.state.voucher.length === 0}
                                                        > Ajouter le code </button>
                                                </div>
                                            </div>

                                            <h2 className="text-semibold">VOS MOYENS DE PAIEMENT</h2>

                                            <div className="line " style={{ width: (window.innerWidth <= 1024) ? "50%" : "29vmax", marginBottom: 10 }} />

                                            {(!this.state.show_add_card || this.state.my_cards.length > 0) &&
                                                <div className="flex suumary-width" style={{ alignItems: 'flex-end' }}>
                                                    <p className="text font-size black" style={{ fontFamily: "Montserrat-Bold", cursor: 'pointer' }} onClick={() => this.setState({ show_add_card: !this.state.show_add_card })}>{this.state.show_add_card ? "Voir mes cartes" : "Ajouter une carte ?"}</p>
                                                </div>
                                            }

                                            {this.state.my_cards.length > 0 && !this.state.show_add_card &&

                                            <> 
                                            
                                            <p className="text-medium" style={{marginBottom: 0}}>Choisissez une carte ci-dessous ou <span onClick={() => this.setState({ show_add_card: !this.state.show_add_card })} style={{cursor: 'pointer', textDecoration: 'underline'}}>ajoutez en une</span>  </p>

                                            {
                                                this.state.my_cards.map((el, index) => (
                                                    <button 
                                                        onClick={() => this.checkItem(el, index)}
                                                        className="summary-width" 
                                                        style={{ backgroundColor: '#F9F9F9',  border: '1px solid rgba(0,0,0,0.2)' , padding: 10, borderRadius: 5, marginBottom: 5, marginTop: index == 0 ? 20 : 5,  cursor: 'pointer' }}>
                                                        <div className='row center '>

                                                            <label className="black font-size" value={el.id} >
                                                                Carte n°<span className="green" style={{ fontFamily: "Montserrat-Bold" }}> {el.alias}</span>
                                                            </label>
                                                            {this.state.checked.includes(index) &&
                                                                <SvgTick />
                                                            }

                                                        </div>

                                                    </button>
                                                ))
                                            }
                                            </>

                                                

                                            }

                                            

                                            {(this.state.show_add_card || this.state.my_cards.length == 0) &&
                                                <div className="flex">
                                                    <p className="text-medium" >Ajouter votre carte bancaire</p>
                                                    <CreditCardInput
                                                        cardNumberInputProps={{ value: this.state.cardNumber, onChange: (value) => { this.setState({ number: value.target.value }) } }}
                                                        cardExpiryInputProps={{ value: this.state.expiry, onChange: (value) => { this.setState({ expiry: value.target.value }) } }}
                                                        cardCVCInputProps={{ value: this.state.cvc, onChange: (value) => { this.setState({ cvc: value.target.value }) } }}
                                                        fieldClassName="input"
                                                        customTextLabels={{
                                                            cardNumberPlaceholder: 'Numéro de la carte',
                                                            expiryPlaceholder: 'MM/AA',
                                                            cvcPlaceholder: 'CVC',
                                                        }}
                                                        containerStyle={{
                                                            border: "1px solid rgba(0,0,0,0.2)",
                                                            borderRadius: "5px"
                                                        }}
                                                        inputStyle={{ fontFamily: "Montserrat-Regular", fontSize: '12px', color: '#222222', backgroundColor: '#F9F9F9' }}
                                                        fieldStyle={{ backgroundColor: '#F9F9F9', height: (this.state.height > this.state.width) && '4vmax' }}

                                                    />
                                                    <Button
                                                        text="AJOUTER LA CARTE"
                                                        width={(this.state.height > this.state.width) || (window.innerWidth <= 1024) ? "70vmin" : '29vmax'}
                                                        onClick={this.createCard.bind(this)}
                                                        disabled={this.state.number.length === 0 || this.state.cvc.length === 0 || this.state.expiry.length === 0}
                                                        marginTop={30}
                                                    />
                                                </div>
                                            }

                                            {this.state.my_cards.length > 0 && !this.state.show_add_card &&

                                                <div className="flex summary-width" style={{ marginTop: 20 }}>
                                                    <div className='row center' style={{ justifyContent: 'flex-start', alignItems: (this.state.height > this.state.width) ? 'flex-start' : 'center' }}>
                                                        <CheckButton selected={this.state.accept} onClick={() => this.setState({ accept: !this.state.accept })} />
                                                        <label className="font-size" style={{ color: "#222222", marginLeft: 5 }} onClick={() => this.setState({ accept: !this.state.accept })}>
                                                            J’ai lu et j’accepte les Conditions Générales de Vente * <br />
                                                    </label>
                                                    </div>


                                                    <Button
                                                        text="COMMANDER"
                                                        width={(this.state.height > this.state.width) || (window.innerWidth <= 1024) ? "70vmin" : '29vmax'}

                                                        onClick={this.confirm.bind(this)}
                                                        disabled={( this.state.user && (this.state.user.is_professional && (!this.state.collaborator))) || !this.state.accept || this.state.selected_card == null}
                                                    />

                                                    { (this.state.user && this.state.user.is_professional && (!this.state.collaborator)) && "* Vous avez besoin de séléctionner un invité pour commander" }
                                                </div>}



                                        </div>
                                    </div>
                                    </div>

                                    </>
                                )

                            }

                        })()
                    }

                    <Modal
                        isOpen={this.state.show_modal}
                        onRequestClose={this.closeModal.bind(this)}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <FadeIn>
                            <div className="close">
                                <button style={{ backgroundColor: 'white', border: 0, cursor: 'pointer', padding: 5 }} onClick={this.closeModal.bind(this)}>
                                    <SvgClose />
                                </button>
                            </div>

                            {

                                (() => {

                                    if (this.state.showMyReservations) {
                                        return (<MyBookings
                                            mybookings={this.state.mybookings}
                                        />)
                                    } else if (this.state.show_login) {
                                        return (
                                            <Login
                                                email={this.state.email}
                                                onChangeEmail={(event) => this.setState({ email: event.target.value })}
                                                password={this.state.password}
                                                onChangePassword={(event) => this.setState({ password: event.target.value })}
                                                login_width={(this.state.height > this.state.width) ? '60vmin' : '22vmax'}
                                                login={this.login.bind(this)}
                                                register={() => this.setState({ show_login: false })}
                                            />
                                        )
                                    } else if (this.state.show_register_pro) {

                                        return (
                                            <RegisterPro
                                                width_register_button={(this.state.height > this.state.width) ? '60vmin' : '22vmax'}
                                                width_name_input={(this.state.height > this.state.width) ? '26vmin' : "11vmax"}
                                                width_input={(this.state.height > this.state.width) ? '24vmin' : "9vmax"}
                                                lastname={this.state.lastname}
                                                onChangeLastname={(event) => this.setState({ lastname: event.target.value })}
                                                firstname={this.state.firstname}
                                                onChangeFirstname={(event) => this.setState({ firstname: event.target.value })}
                                                email={this.state.email}
                                                onChangeEmail={(event) => this.setState({ email: event.target.value })}
                                                company_name={this.state.company_name}
                                                onChangeCompanyName={(event) => this.setState({ company_name: event.target.value })}
                                                company_address={this.state.company_address}
                                                onChangeCompanyAddress={(event) => this.setState({ company_address: event.target.value })}
                                                password={this.state.password}
                                                onChangePassword={(event) => this.setState({ password: event.target.value })}
                                                password_confirm={this.state.password_confirm}
                                                onChangePasswordConfirm={(event) => this.setState({ password_confirm: event.target.value })}
                                                register={this.register.bind(this)}
                                                login={() => this.setState({ show_login: true })}
                                                signupPro={() => this.setState({ show_register_pro: false })}
                                            />
                                        )

                                    } else {

                                        return (
                                            < Register
                                                width_register_button={(this.state.height > this.state.width) ? '60vmin' : '22vmax'}
                                                width_name_input={(this.state.height > this.state.width) ? '26vmin' : "11vmax"}
                                                width_input={(this.state.height > this.state.width) ? '24vmin' : "9vmax"}
                                                lastname={this.state.lastname}
                                                onChangeLastname={(event) => this.setState({ lastname: event.target.value })}
                                                firstname={this.state.firstname}
                                                onChangeFirstname={(event) => this.setState({ firstname: event.target.value })}
                                                phone_number={this.state.phone_number}
                                                onChangePhoneNumber={(event) => this.setState({ phone_number: event.target.value })}
                                                email={this.state.email}
                                                onChangeEmail={(event) => this.setState({ email: event.target.value })}
                                                password={this.state.password}
                                                onChangePassword={(event) => this.setState({ password: event.target.value })}
                                                password_confirm={this.state.password_confirm}
                                                onChangePasswordConfirm={(event) => this.setState({ password_confirm: event.target.value })}
                                                register={this.register.bind(this)}
                                                login={() => this.setState({ show_login: true })}
                                                signupPro={() => this.setState({ show_register_pro: true })}
                                            />
                                        )
                                    }

                                })()
                            }

                        </FadeIn>
                    </Modal>



                    <ToastContainer autoClose={10000} />
                </div >
        )
        }

    }
}


export default App;
