import env from './env'

const api = env.api;

class WebServices {

    static myInstance = null;


    static getInstance() {
        if (WebServices.myInstance == null) {
            WebServices.myInstance = new WebServices();
            WebServices.myInstance.token = localStorage.getItem('token');
        }

        return this.myInstance;
    }

    token = localStorage.getItem('token')

    isLogged() {

        return localStorage.getItem('token') != null;
    };

    setToken(token) {

        localStorage.setItem('token', token);
        this.token = token;

    };

    login(user) {

        let body = {
            email: user.email,
            password: user.password
        };

        return new Promise((fullfil, reject) => {
            fetch(`${api}auth`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then((response) => {
                fullfil(response.json())

            }).catch((e) => {
                console.log(e)
            })
        })
    }

    handleErrors(errors) {
        let errorMsgs = '';
        errors.map((e) => {
            errorMsgs = errorMsgs + '\n' + e.msg;
        });
        return errorMsgs;
    }

    requestNewpassword(email) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/password/reset?email=${email}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }

    register(user) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}users`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then((response) => {
                    this.login(user).then(() => {
                        fullfil(response.json());
                    });
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }


    getKeyWordPlaces({ keyword }) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}places/predictions?keyword=${keyword}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getPointFromAdress(address) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}places/points?address=${address}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',

                }
            }).then((response) => {
                fullfil(response.json());
            }).catch(function (error) {
                reject(error);
            });

        });
    }

    getAdressFromPoints(point_lat, point_lon) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}places/address?point_lat=${point_lat}&point_lon=${point_lon}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',

                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }


    getTripPricing(google_place_from, google_place_to, is_one_way, date) {
        return new Promise((fullfil, reject) => {
            console.log('url: ', `${api}v2/trips/pricing?google_place_from=${google_place_from}&google_place_to=${google_place_to}&is_one_way=${is_one_way}&date=${date}`)
            fetch(`${api}v2/trips/pricing?google_place_from=${google_place_from}&google_place_to=${google_place_to}&is_one_way=${is_one_way}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response) => {
                    console.log('hey')
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }

    getAllMyCards() {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/cards`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }


    getCard({ id }) {

        return new Promise((fullfil, reject) => {


            fetch(`${api}users/me/cards/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch((error) => {
                    reject(error);
                });


        });
    }

    createCard(stripe_source) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}users/me/cards`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                },
                body: JSON.stringify(stripe_source)
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    createTrip(trip) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}v2/bookings`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                },
                body: JSON.stringify(trip)
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    updateTrip(status, id) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}bookings/${id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                },
                body: JSON.stringify(status)
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getBooking(id) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}bookings/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }

    getMyBookings() {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/bookings?all=true`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }

    GetUserInformations() {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    response.json().then((res) => {
                        fullfil(res);
                    });

                })
                .catch(function (error) {
                    // console.log('ERRRRRRRRRRRRR')
                    reject(error);
                });
        });
    }

    createCompany(company) {
        return new Promise((fullfil, reject) => {

            try {
                fetch(`${api}users/me/companies`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.token
                    },
                    body: JSON.stringify(company)
                })
                    .then((response) => {
                        fullfil(response.json());
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }

        });
    }

    deleteCompany(id) {
        return new Promise((fullfil, reject) => {
            try {
                fetch(`${api}users/me/companies/${id}`, {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.token
                    }
                })
                    .then((response) => {
                        fullfil(response.json());
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }

    getCompany(id) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/companies/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        fullfil(res.data);
                    } else {
                        reject(res.errors);
                    }
                });
            });
        });
    };


    getAllCompanies() {

        return new Promise((fullfil, reject) => {
            try {
                fetch(`${api}users/me/companies`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.token
                    }
                })
                    .then((response) => {
                        if (response.status == 200) {
                            response.json().then((res) => {
                                console.log("companies: ", res)
                                fullfil(res.data);
                            });
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }


    getAllCollaborators() {

        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/professional/collaborators`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    if (response.status == 200) {
                        response.json().then((res) => {
                            console.log("collaborators: ", res)
                            fullfil(res.data);
                        });
                    }
                })
                .catch(function (error) {
                    reject(error);
                });

        });
    }


    createCollaborator(collaborator) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/professional/collaborators`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                },
                body: JSON.stringify(collaborator)
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    deleteCollaborator(id) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}users/me/professional/collaborators/${id}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getCollaborator(id) {
        return new Promise((fullfil, reject) => {
            fetch(`${api}users/me/professional/collaborators/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        fullfil(res.data);
                    } else {
                        reject(res.errors);
                    }
                });
            });
        });
    };
    Vouchers(code) {
        return new Promise((fullfil, reject) => {

            fetch(`${api}vouchers?code=${code}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.token
                }
            })
                .then((response) => {
                    fullfil(response.json());
                })
                .catch(function (error) {
                    reject(error);
                });


        });
    }

}
export default WebServices.getInstance();